import styled, { css } from "styled-components"

export const Date = styled.div`
  font-size: 13px;
  color: #999;
  line-height: 1em;
  top: 5px;
  position: relative;
  z-index: 2;
`

export const ItemContainer = styled.div<{
  show?: boolean
  collapse?: boolean
  inactive?: boolean
}>`
  border-left: 2px dashed #eee;
  padding-left: 10px;
  min-height: 50px;
  &:before {
    content: "";
    width: 10px;
    height: 10px;
    background-color: #8bc34a;
    border-radius: 99px;
    position: relative;
    left: -16px;
    top: 16px;
    display: block;
    z-index: 1;
  }
  ${({ collapse }) =>
    collapse &&
    css`
      min-height: 0;
    `}
  ${({ inactive }) =>
    inactive &&
    css`
      cursor: pointer;
      ${Date} {
        color: #dedede;
      }
      &:before {
        background-color: #eee;
      }
      &:hover {
        &:before {
          background-color: #ddd;
        }
        ${Date} {
          color: #bbb;
        }
      }
    `}
`

export const TextInputContainer = styled.div`
  padding: 0 10px;
`

export const LoadPrevious = styled.div`
  width: 26px;
  height: 26px;
  position: relative;
  left: -14px;
  top: 3px;
  display: block;
`

export const AddNew = styled.div`
  width: 26px;
  height: 26px;
  position: relative;
  left: -12px;
  top: 7px;
  display: block;
  text-align: center;
  svg {
    width: 16px;
    height: 16px;
  }
`

export const Contract = styled.div`
  width: 26px;
  height: 26px;
  position: relative;
  left: -14px;
  top: 3px;
  display: block;
`

export const Container = styled.div`
  margin: -10px 0 10px 5px;
`

export const DatePicker = styled.input`
  border: 0;
  font-family: inherit;
  font-size: 13px;
  height: 13px;
  line-height: 13px;
  color: #999;
  background-color: transparent;
  margin-left: 2px;
  outline: none;
`
