import React, { useContext, useEffect } from "react"
import { authContext } from "../../hooks/useAuth"
import { NotesContext } from "../../contexts"

const LoginPage = () => {
  const { user } = useContext(authContext);
  const isLoggedIn = !!user?.uid

  const { notes } = useContext(NotesContext) ?? {
    notes: {}
  }

  useEffect(() => {
    if (Object.keys(notes ?? {})?.[0]) {
      const allNotes = Object.keys(notes ?? {})
      const sortedNotesAlphabetically = allNotes.sort()
      const firstNote = sortedNotesAlphabetically?.[0]
      window.location.href = `/${firstNote}`
    }
  }, [isLoggedIn, notes, history])

  return <></>
}

export default LoginPage
