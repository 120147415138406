import React, { createContext, useEffect, useState, useCallback } from "react";
import { NotesContextProps } from "./types";
import { useDatabase } from "../hooks/useDatabase";
import { useAuth } from "../hooks/useAuth";

export const NotesContext = createContext<NotesContextProps | null>(null);

export function ProvideNotes({ children }: { children: React.ReactChild }) {
  const { getNotes, postNotes, error } = useDatabase();
  const { user } = useAuth();
  const userId = user?.uid;

  const [notes, setNotes] = useState<NotesContextProps['notes'] | null>(null);

  const syncNotes = useCallback(async () => {
    const data = await getNotes();
    if (!data) return;
    setNotes(data);
  }, [getNotes]);

  const saveNotes = useCallback(
    (data: NotesContextProps['notes']) => {
      postNotes(data);
    },
    [postNotes]
  );

  useEffect(() => {
    syncNotes();
  }, [userId]);

  return (
    <NotesContext.Provider value={{ notes, setNotes: saveNotes, syncNotes, error }}>
      {children}
    </NotesContext.Provider>
  );
}
