import React, { useCallback, useState, useEffect } from "react"
import { CodeInput, SyntaxHighlighterContainer } from "./CodeBlock.styled"
import { CodeBlockProps } from "./types"
import SyntaxHighlighter from "react-syntax-highlighter"
import style from "react-syntax-highlighter/dist/esm/styles/hljs/hybrid"

export const CodeBlock = ({ onChange, defaultValue }: CodeBlockProps) => {
  const [rowCount, setRowCount] = useState(
    defaultValue ? defaultValue.split(`\n`).length : 1
  )
  const [value, setValue] = useState(defaultValue)
  const [editorVisible, setEditorVisible] = useState<boolean>(
    !value || value === ``
  )

  const shouldHighlightCode = !editorVisible && value && value !== ``

  useEffect(() => {
    setValue(defaultValue)
  }, [defaultValue])

  const handleOnChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      const value = e?.target?.value ?? ``
      setEditorVisible(true)
      setValue(value)
      setRowCount(value.split(`\n`).length)
      onChange?.(value)
    },
    [onChange]
  )

  const handleDoubleClick = useCallback(() => {
    setEditorVisible((prevState) => !prevState)
  }, [])

  return (
    <>
      {!shouldHighlightCode && (
        <CodeInput
          onChange={handleOnChange}
          value={value}
          rows={rowCount}
          spellCheck="false"
          onDoubleClick={handleDoubleClick}
        />
      )}
      {shouldHighlightCode && (
        <SyntaxHighlighterContainer>
          <SyntaxHighlighter
            language="javascript"
            style={style}
            onDoubleClick={handleDoubleClick}
          >
            {value}
          </SyntaxHighlighter>
        </SyntaxHighlighterContainer>
      )}
    </>
  )
}

export default CodeBlock
