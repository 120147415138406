import React from "react"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import { Grid } from "@mui/material"
import NotePage from "./pages/NotePage"
import NoteList from "./components/NoteList"
import { createTheme } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"
import MobileMenu from "./components/MobileMenu"
import { ProvideAuth } from "./hooks/useAuth"
import { ProvideNotes } from "./contexts"
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles"
import Homepage from "./components/Homepage"
import LoginPage from "./components/LoginPage"

function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: `#556cd6`
      }
    }
  })

  const isMediumUp = useMediaQuery(theme.breakpoints.up(`md`))

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <ProvideAuth>
          <ProvideNotes>
            <Router>
              <Switch>
                <Route exact path="/home">
                  <Homepage />
                </Route>
                <Route exact path="/login">
                  <LoginPage />
                </Route>
                <Route path="/:id?">
                  {!isMediumUp && <MobileMenu />}
                  <Grid container>
                    {isMediumUp && (
                      <Grid item xs={2}>
                        <NoteList />
                      </Grid>
                    )}
                    <Grid item xs={12} md={10}>
                      <NotePage />
                    </Grid>
                  </Grid>
                </Route>
              </Switch>
            </Router>
          </ProvideNotes>
        </ProvideAuth>
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

export default App
