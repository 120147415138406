import React, { useCallback, useRef, useEffect, useState } from "react"
import { CountdownBlockProps } from "./types"
import { Grid } from "@mui/material"
import styled from "styled-components"
import { differenceInSeconds, parseISO } from "date-fns"

const Track = styled.div`
  width: 100%;
  height: 30px;
  background-color: #e8e9f1;
`

const Bar = styled.div<{ percentage: number }>`
  width: ${({ percentage }) => percentage}%;
  height: 30px;
  background-color: #5e7ff5;
  transition: all 1s linear;
`

const MaxWidth = styled.div`
  max-width: 700px;
`

const Title = styled.div`
  outline: none;
`

const DateInput = styled.input`
  border: 0;
  font-family: inherit;
  font-size: 13px;
  height: 13px;
  line-height: 13px;
  color: #999;
  background-color: transparent;
  margin-left: 2px;
  outline: none;
`

const RemainingTime = styled.span`
  cursor: pointer;
`

const secondsSinceDatetime = (datetime: string) => {
  const date = parseISO(datetime)
  const now = new Date()
  const diff = differenceInSeconds(date, now)
  return diff
}

const formatSeconds = (seconds: number) => {
  const isLessThan60Seconds = seconds < 60
  const isLessThan1Hour = seconds < 3600
  const isLessThan1Day = seconds < 86400
  const isLessThan1Year = seconds < 31536000
  const isMoreThan1Year = seconds >= 31536000

  if (isLessThan60Seconds) {
    return `${seconds} seconds`
  }

  if (isLessThan1Hour) {
    const minutes = Math.floor(seconds / 60)
    return `${minutes} minutes`
  }

  if (isLessThan1Day) {
    const hours = Math.floor(seconds / 3600)
    const remainingSeconds = seconds % 3600
    const minutes = Math.floor(remainingSeconds / 60)
    return `${hours} hours ${minutes} minutes`
  }

  if (isLessThan1Year) {
    const days = Math.floor(seconds / 86400)
    return `${days} days`
  }

  if (isMoreThan1Year) {
    const years = Math.floor(seconds / 31536000)
    const remainingSeconds = seconds % 31536000
    const days = Math.floor(remainingSeconds / 86400)
    return `${years} years ${days} days`
  }
}

export const CountdownBlock = ({
  onChange,
  defaultValue
}: CountdownBlockProps) => {
  const [currentSeconds, setCurrentSeconds] = useState(new Date().getTime())
  const [secondsLeft, setSecondsLeft] = useState(0)
  const [dateInputVisible, setDateInputVisible] = useState(
    !defaultValue?.endDatetime
  )

  const titleRef = useRef<HTMLDivElement>(null)

  const remainingTime = formatSeconds(secondsLeft)
  const timeOverdue = formatSeconds(secondsLeft * -1)

  const handleDatetimeChange = useCallback(
    (event) => {
      onChange?.({
        ...defaultValue,
        startDatetime: new Date().toISOString(),
        endDatetime: new Date(event.target.value).toISOString()
      })
    },
    [onChange, defaultValue]
  )

  const handleTitleChange = useCallback(
    (event) => {
      onChange?.({
        ...defaultValue,
        title:
          event.target.innerText && event.target.innerText !== ``
            ? event.target.innerText
            : `My Countdown Timer`
      })
    },
    [onChange, defaultValue]
  )

  const handleDateInputBlur = useCallback((event) => {
    setDateInputVisible(false)
  }, [])

  useEffect(() => {
    if (!titleRef.current) return
    const isTitleElEmpty = titleRef.current.innerText === ``

    if (isTitleElEmpty) {
      titleRef.current.innerText = defaultValue?.title ?? `My Countdown Timer`
    }
  }, [defaultValue?.title])

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSeconds(new Date().getTime())
    }, 1000)

    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    if (!defaultValue?.endDatetime) return
    const seconds = secondsSinceDatetime(defaultValue.endDatetime)
    setSecondsLeft(seconds)
  }, [defaultValue?.endDatetime, currentSeconds])

  const timeBetweenStartAndEnd =
    new Date(defaultValue?.endDatetime).getTime() -
    new Date(defaultValue?.startDatetime).getTime()
  const percentageBetweenStartAndEnd =
    ((currentSeconds - new Date(defaultValue?.startDatetime).getTime()) /
      timeBetweenStartAndEnd) *
    100

  return (
    <MaxWidth>
      <Grid container spacing={1} alignItems="center">
        <Grid item xs={12} md="auto">
          <Title
            contentEditable={true}
            onInput={handleTitleChange}
            ref={titleRef}
          />
        </Grid>
        <Grid item xs={12} md>
          <Track>
            <Bar
              percentage={
                percentageBetweenStartAndEnd >= 100
                  ? 0
                  : 100 - percentageBetweenStartAndEnd
              }
            />
          </Track>
        </Grid>
        <Grid item xs={12} md="auto">
          {dateInputVisible && (
            <DateInput
              type="datetime-local"
              onChange={handleDatetimeChange}
              onBlur={handleDateInputBlur}
            />
          )}
          {!dateInputVisible && (
            <RemainingTime onClick={() => setDateInputVisible(true)}>
              {percentageBetweenStartAndEnd < 100 && `${remainingTime} left`}
              {percentageBetweenStartAndEnd >= 100 && `${timeOverdue} overdue`}
            </RemainingTime>
          )}
        </Grid>
      </Grid>
    </MaxWidth>
  )
}

export default CountdownBlock
