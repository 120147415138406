import React, { useCallback, useState } from "react"
import RichTextInput from "../../RichTextInput"
import { HiddenBlockProps } from "./types"
import { VisibilityGate, LockIcon, LockOpenIcon } from "./HiddenBlock.styled"
import { Grid } from "@mui/material"
import { useIdleTimer } from "react-idle-timer"
import Skeleton from "@mui/lab/Skeleton"

export const HiddenBlock = ({
  onChange,
  defaultValue,
  id
}: HiddenBlockProps) => {
  const [visible, setVisible] = useState<boolean>(false)

  useIdleTimer({
    timeout: 60000,
    onIdle: () => setVisible(false),
    debounce: 500
  })

  const handleOnChange = useCallback(
    (value: string) => {
      onChange?.(value)
    },
    [onChange]
  )

  const handleVisibilityClick = useCallback(() => {
    setVisible((prevState) => !prevState)
  }, [])

  return (
    <Grid container wrap="nowrap" spacing={1}>
      <Grid item>
        <VisibilityGate onClick={handleVisibilityClick}>
          <LockIcon />
          <LockOpenIcon />
        </VisibilityGate>
      </Grid>
      <Grid item xs={12}>
        {!visible && (
          <VisibilityGate onClick={handleVisibilityClick}>
            <Skeleton
              variant="text"
              width={150}
              height={10}
              animation={false}
            />
            <Skeleton
              variant="text"
              width={180}
              height={10}
              animation={false}
            />
            <Skeleton
              variant="text"
              width={160}
              height={10}
              animation={false}
            />
            <Skeleton
              variant="text"
              width={170}
              height={10}
              animation={false}
            />
            <Skeleton
              variant="text"
              width={100}
              height={10}
              animation={false}
            />
          </VisibilityGate>
        )}
        {visible && (
          <RichTextInput
            onChange={handleOnChange}
            defaultValue={defaultValue ?? ``}
            id={id}
          />
        )}
      </Grid>
    </Grid>
  )
}

export default HiddenBlock
