import React, { useState, useContext, useEffect } from "react"
import { Grid, Box } from "@mui/material"
import HiddenBlock from "../Blocks/HiddenBlock"
import TextBlock from "../Blocks/TextBlock"
import TimelineBlock from "../Blocks/TimelineBlock"
import LineBlock, { DataItem } from "../Blocks/LineChartBlock"
import CodeBlock from "../Blocks/CodeBlock"
import CuboidMeasurementBlock from "../Blocks/CuboidMeasurementBlock"
import { subDays, addDays, format } from "date-fns"
import { hotjar } from "react-hotjar"
import {
  SlantedRectangle,
  Header,
  MainTitle,
  MainSubitle,
  Button,
  Paragraph,
  MailchimpForm,
  DeviceContainer,
  Smartphone,
  DeviceInnerContainer,
  ScaleBlock,
  Desktop,
  SecondSection,
  MaxWidthContainer,
  Title,
  WidgetsGraphic,
  WidgetOne,
  WidgetInnerContainer,
  WidgetTwo,
  WidgetThree,
  WidgetFour,
  WidgetFive,
  WidgetSix,
  ThirdSection,
  TextEditorGraphic,
  FourthSection,
  Subtitle,
  Footer,
  PeabayLogo
} from "./Homepage.styles"

const randomData = (length: number): Array<DataItem> => {
  const data = [] as Array<DataItem>
  for (let i = 0; i < length; i++) {
    const min = 65
    const max = 100
    const random = Math.floor(Math.random() * (max - min)) + min

    data.push({
      x: format(subDays(new Date(), length - i), `yyyy-MM-dd HH:mm:ss`),
      y: random.toString()
    })
  }
  return data
}

const randomChartData = randomData(10)

const deviceTimelineData = [
  {
    timestamp: subDays(new Date(), 1).getTime() / 1000,
    text: `Have a rest, you deserve it.`
  },
  {
    timestamp: new Date().getTime() / 1000,
    text: `Today's agenda: go to the gym, buy groceries, call angela. Her number is 123-456-7890. But only after 5pm. She likes to sleep in.`
  },
  {
    timestamp: addDays(new Date(), 1).getTime() / 1000,
    text: `Must remember to call the dentist! Pick up some milk on the way home.`
  }
]

const deviceTextData = `<h2>Sourdough Loaf</h2><ol><li>Knead until stretchy</li><li>Prove for 3 hours&nbsp;</li><li>Knock out air, fold in on itself and make into ball, then put in basket&nbsp;</li><li>Prove for 3 hours</li><li>Bake with tray if water for 20 minutes or until brown&nbsp;</li></ol>`

const Homepage = () => {
  const [showSignupForm, setShowSignupForm] = useState(false)

  return (
    <div>
      <SlantedRectangle />
      <Header>
        <Grid container>
          <Grid item xs={12} sm={6} md={5}>
            <MainTitle>A notepad powered by components</MainTitle>
            <MainSubitle>
              A new way to visualize notes using interactive blocks. Organise
              your components into a layout that works for you, and view them on
              any device, online or offline, no installation required.
            </MainSubitle>
            {!showSignupForm && (
              <Button
                onClick={() => {
                  setShowSignupForm(true)
                  hotjar.event(`get-started-click`)
                }}
              >
                Get started
              </Button>
            )}
            {showSignupForm && (
              <>
                <Paragraph>
                  Blot is currently in private beta. Request early access to be
                  notified when it's ready.
                </Paragraph>

                <MailchimpForm
                  action="https://blotnotes.us10.list-manage.com/subscribe/post?u=1782af7967a5964b0ad8ee257&amp;id=70af9db9de&amp;f_id=00a2b7e5f0"
                  method="post"
                  target="_self"
                >
                  <input
                    type="email"
                    name="EMAIL"
                    id="mce-EMAIL"
                    required
                    placeholder="Email"
                  />

                  <div
                    style={{ position: `absolute`, left: `-5000px` }}
                    aria-hidden="true"
                  >
                    <input
                      type="text"
                      name="b_1782af7967a5964b0ad8ee257_70af9db9de"
                      tabIndex={-1}
                      value=""
                    />
                  </div>
                  <div>
                    <Button
                      type="submit"
                      value="Subscribe"
                      name="subscribe"
                      id="mc-embedded-subscribe"
                    >
                      Request early access
                    </Button>
                  </div>
                  <p>
                    <a
                      href="http://eepurl.com/ijik0r"
                      title="Mailchimp - email marketing made easy and fun"
                    >
                      <img
                        src="https://eep.io/mc-cdn-images/template_images/branding_logo_text_dark_dtp.svg"
                        alt="email logo"
                      />
                    </a>
                  </p>
                </MailchimpForm>
              </>
            )}
          </Grid>
          <Box
            component={Grid}
            item
            xs={7}
            sm={6}
            display={{ xs: `none`, sm: `block` }}
          >
            <DeviceContainer>
              <Smartphone>
                <DeviceInnerContainer>
                  <ScaleBlock>
                    <TimelineBlock
                      onChange={() => {}}
                      defaultValue={deviceTimelineData}
                      id="homepage-1"
                    />
                    <TextBlock
                      onChange={() => {}}
                      defaultValue={deviceTextData}
                      id="header-3"
                    />
                    <LineBlock
                      onChange={() => {}}
                      defaultValue={[
                        {
                          label: `Weight`,
                          data: randomChartData
                        }
                      ]}
                    />
                  </ScaleBlock>
                </DeviceInnerContainer>
              </Smartphone>
              <Desktop>
                <DeviceInnerContainer>
                  <TimelineBlock
                    onChange={() => {}}
                    defaultValue={deviceTimelineData}
                    id="homepage-1"
                  />
                  <TextBlock
                    onChange={() => {}}
                    defaultValue={deviceTextData}
                    id="header-3"
                  />
                  <LineBlock
                    onChange={() => {}}
                    defaultValue={[
                      {
                        label: `Weight`,
                        data: randomChartData
                      }
                    ]}
                  />
                </DeviceInnerContainer>
              </Desktop>
            </DeviceContainer>
          </Box>
        </Grid>
      </Header>
      <SecondSection>
        <MaxWidthContainer>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <Title>A fully integrated suite of components</Title>
              <Paragraph>
                Blot's components are designed to present your notes in the best
                way possible with a focus on interactivity and visual appeal.
                Put them together, rearrange them, and see what works for you.
              </Paragraph>
            </Grid>
          </Grid>
          <WidgetsGraphic>
            <WidgetOne>
              <WidgetInnerContainer>
                <TimelineBlock
                  onChange={() => {}}
                  defaultValue={[
                    {
                      timestamp: subDays(new Date(), 1).getTime() / 1000,
                      text: `This is a timeline block. You can edit it by clicking on it.`
                    },
                    {
                      timestamp: new Date().getTime() / 1000,
                      text: `This is a timeline component. It shows notes for today by default. You can schedule notes to appear in the future, or view notes from the past.`
                    },
                    {
                      timestamp: addDays(new Date(), 1).getTime() / 1000,
                      text: `This is a timeline block. You can edit it by clicking on it.`
                    }
                  ]}
                  id="homepage-1"
                />
              </WidgetInnerContainer>
            </WidgetOne>
            <WidgetTwo>
              <WidgetInnerContainer>
                <LineBlock
                  onChange={() => {}}
                  defaultValue={[
                    {
                      label: `Apples`,
                      data: randomData(100)
                    },
                    {
                      label: `Oranges`,
                      data: randomData(100)
                    }
                  ]}
                />
              </WidgetInnerContainer>
            </WidgetTwo>
            <WidgetThree>
              <WidgetInnerContainer>
                <TextBlock
                  onChange={() => {}}
                  defaultValue="
                    <div>This is a text block. You can edit it by clicking on it and add formatting via the floating toolbar.</div>
                    <div>
                    <div><br></div>
                    <h2>Swimming Tips</h2><ul><li>Lift head a few moments after starting pull</li><li>High elbow&nbsp;</li><li>Hands shouldn't go past shoulders</li><li>Short arm strokes</li><li>Hands come together under chest</li><li>Lift bottom up after kick</li><li>Keep shoulders rolled forward&nbsp;</li></ul><div><br></div>
                    </div>
                  "
                  id="homepage-3"
                />
              </WidgetInnerContainer>
            </WidgetThree>
            <WidgetFour>
              <WidgetInnerContainer>
                <HiddenBlock
                  onChange={() => {}}
                  defaultValue="This is a hidden component. It's hidden by default, but you can reveal it by clicking on it. When you are idle, it will hide itself again."
                  id="homepage-4"
                />
              </WidgetInnerContainer>
            </WidgetFour>
            <WidgetFive>
              <WidgetInnerContainer>
                <CodeBlock
                  onChange={() => {}}
                  defaultValue={`// This is a code block. It supports syntax highlighting for many languages. You can edit it by double clicking on it.

  import React, { useState } from 'react';

  function Counter() {
    const [count, setCount] = useState(0);

    return (
      <div>
        <p>You clicked {count} times</p>
        <button onClick={() => setCount(count + 1)}>
          Click me
        </button>
      </div>
    );
                  }`}
                />
              </WidgetInnerContainer>
            </WidgetFive>
            <WidgetSix>
              <div style={{ margin: `0 auto` }}>
                <CuboidMeasurementBlock
                  onChange={() => {}}
                  defaultValue={{
                    width: `100cm`,
                    length: `100cm`,
                    depth: `50cm`
                  }}
                />
              </div>
            </WidgetSix>
          </WidgetsGraphic>
        </MaxWidthContainer>
      </SecondSection>
      <ThirdSection>
        <MaxWidthContainer>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <Title>Everything you'd expect from a notepad too</Title>
              <Paragraph>
                Our text components provides all formatting features you're used
                to in a notepad and can be mixed with other interactive
                components to build a dynamic layout.
              </Paragraph>
              <Paragraph>
                Notes are saved automatically as you type so you can focus on
                writing. Settings and menus are kept to the absolute minimum to
                keep the interface clean and uncluttered.
              </Paragraph>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextEditorGraphic>
                <TextBlock
                  onChange={() => {}}
                  defaultValue="
                  <div>This is a text block. You can edit it by clicking on it and add formatting via the floating toolbar. It even supports shortcuts.</div><div><br></div><div><strong>Create a bullet list</strong></div><div>Type - and press space</div><ul><li>Apples</li><li>Oranges</li><li>Pears</li></ul><div><strong>Create a numbered list</strong></div><div>Type 1. and press space.</div><ol><li>Sun</li><li>Sea</li><li>Sand</li></ol><div><strong>Create a code block</strong></div><div>Type ``` and press enter .</div><pre>const random = function (min: number, max: number) {<br />  return Math.floor(Math.random() * (max - min + 1) + min)<br />}</pre><div><strong>Highlight text</strong></div><div>Wrap text in two `.<br><br>Make it <span style='background-color: rgba(94, 127, 245, 0.05); border: 1px solid rgba(94, 127, 245, 0.4); padding: 0px 3px; border-radius: 3px; color: rgb(0, 0, 0);'>stand</span>&nbsp;out.</div>
                  "
                  id="editor-3"
                />
              </TextEditorGraphic>
            </Grid>
          </Grid>
        </MaxWidthContainer>
      </ThirdSection>
      <FourthSection>
        <MaxWidthContainer>
          <Title>A seamless alternative to note taking apps</Title>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6} md={3}>
              <Subtitle noTopMargin>Device Syncing</Subtitle>
              <Paragraph>
                There is no save or sync button. Your notes are automatically
                saved and synced across all your devices.
              </Paragraph>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Subtitle noTopMargin>Backups</Subtitle>
              <Paragraph>
                The last 3 days of your notes are automatically backed up as
                standard. Backups can be restored at any time or downloaded for
                safe keeping.
              </Paragraph>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Subtitle noTopMargin>Works Offline</Subtitle>
              <Paragraph>
                Notes can be accessed without an internet connection. Any
                changes made while offline will be synced when you're back
                online.
              </Paragraph>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Subtitle noTopMargin>No Installation</Subtitle>
              <Paragraph>
                As a web app there is nothing to install and no permissions to
                grant. Everything looks and feels the same on every device.
                Simply add a shortcut to your home screen.
              </Paragraph>
            </Grid>
          </Grid>
        </MaxWidthContainer>
      </FourthSection>
      <Footer>
        <a href="https://peabay.xyz" target="_blank">
          <PeabayLogo />
        </a>
      </Footer>
    </div>
  )
}

export default Homepage
