import styled from "styled-components"

export const CodeInput = styled.textarea`
  background-color: #fafafa;
  border-radius: 5px;
  border: 1px solid #eee;
  min-height: 1rem;
  width: 100%;
  padding: 10px;
  white-space: pre;
  box-sizing: border-box;
  font: inherit;
  display: block;
  &:focus {
    outline: none;
  }
`

export const SyntaxHighlighterContainer = styled.div`
  margin: -0.5em 0;
`