import { useContext, useState } from "react";
import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import { authContext } from "./useAuth";
import { NotesContextProps } from "../contexts/types";
import { format } from "date-fns";
import { pickBy } from "lodash";

export const useDatabase = () => {
  const { user, firebaseApp } = useContext(authContext);
  const [error, setError] = useState<string | undefined>(undefined);

  const getNotes = () => {
    if (!user?.uid) return;

    setError(undefined);

    const db = firebase.firestore(firebaseApp);
    const docRef = db.collection(`users`).doc(user?.uid);
    const backupDocRef = db.collection(`backups`).doc(user?.uid);

    return docRef
      .get()
      .then(function (doc) {
        if (doc.exists) {
          const data = doc.data();
          console.log(JSON.stringify(data));
          pruneBackups(data, backupDocRef);
          return data?.notes ?? {};
        }
      })
      .catch(function (error) {
        setError(`Error getting document`);
        console.log(error)
      });
  };

  const postNotes = (notes: NotesContextProps['notes']) => {
    if (!user?.uid || !notes) return;

    setError(undefined);

    const db = firebase.firestore(firebaseApp);
    const docRef = db.collection(`users`).doc(user?.uid);
    const backupDocRef = db.collection(`backups`).doc(user?.uid);

    const dateToday = format(new Date(), `dd-MM-yyyy`);

    return Promise.all([
      docRef
        .update({
          notes
        })
        .catch((err) => {
          if (err) {
            setError("Save failed, see console log for details");
            console.log(err);
          }
        })
      ,
      backupDocRef
        .update({
          [`${dateToday}`]: notes,
        })
        .catch((err) => {
          if (err) {
            setError("Backup save failed, see console log for details");
            console.log(err);
          }
        })
    ])
  };

  const pruneBackups = (data, docRef) => {
    if (Object.keys(data).length > 3) {
      const backupKeys = Object.keys(data);
      backupKeys.sort((a, b) => {
        const dataA = a.split("-");
        const dataB = b.split("-");
        let dateA = new Date(`${dataA[1]}-${dataA[0]}-${dataA[2]}`).getTime();
        let dateB = new Date(`${dataB[1]}-${dataB[0]}-${dataB[2]}`).getTime();
        return dateB - dateA;
      });
      const backupsToRemove = backupKeys.slice(3);

      const newBackups = data;

      backupsToRemove.forEach((item) => {
        delete newBackups[item];
      });

      pickBy(newBackups, (v) => v !== undefined);

      docRef.update(newBackups);
    }
  };

  return {
    getNotes,
    postNotes,
    error
  };
};
