import React, { forwardRef } from "react"
import {
  MenuContainer,
  ToolbarItem,
  ToolbarContainer,
  ToolbarIconContainer
} from "./Toolbar.styled"
import {
  FormatBold,
  FormatItalic,
  FormatUnderlined,
  StrikethroughS,
  FormatQuote,
  FormatListNumbered,
  FormatListBulleted,
  InsertLink,
  FormatIndentIncrease,
  FormatIndentDecrease,
  Undo,
  Redo
} from "@mui/icons-material"
import { Actions, ToolbarProps } from "./types"

export const Toolbar = ({ id, open, editor, top, left }: ToolbarProps, ref) => {
  const actions: Actions = {
    bold: {
      attribute: `bold`,
      icon: <FormatBold />,
      title: `Bold`,
      key: `b`
    },
    italic: {
      attribute: `italic`,
      icon: <FormatItalic />,
      title: `Italic`,
      key: `i`
    },
    underline: {
      attribute: `underline`,
      icon: <FormatUnderlined />,
      title: `Underline`,
      key: `u`
    },
    strikethrough: {
      attribute: `strikethrough`,
      icon: <StrikethroughS />,
      title: `Strike`
    },
    heading1: {
      attribute: `heading1`,
      icon: `H1`,
      title: `Heading 1`
    },
    heading2: {
      attribute: `heading2`,
      icon: `H2`,
      title: `Heading 2`
    },
    paragraph: {
      attribute: `paragraph`,
      icon: `P`,
      title: `Paragraph`
    },
    quote: {
      attribute: `quote`,
      icon: <FormatQuote />,
      title: `Quote`
    },
    olist: {
      attribute: `number`,
      icon: <FormatListNumbered />,
      title: `Ordered List`
    },
    ulist: {
      attribute: `bullet`,
      icon: <FormatListBulleted />,
      title: `Unordered List`
    },
    pre: {
      attribute: `code`,
      icon: `PRE`,
      title: `No Wrap`
    },
    outdent: {
      action: `decreaseNestingLevel`,
      icon: <FormatIndentDecrease />,
      title: `Outdent`
    },
    indent: {
      action: `increaseNestingLevel`,
      icon: <FormatIndentIncrease />,
      title: `Indent`
    },
    link: {
      attribute: `href`,
      icon: <InsertLink />,
      title: `Link`,
      key: `k`,
      callback: () => {
        const url = window.prompt(`Enter the link URL`)
        if (!editor || !url) return
        editor.activateAttribute(`href`, url)
      }
    },
    undo: {
      action: `undo`,
      icon: <Undo />,
      title: `Undo`
    },
    redo: {
      action: `redo`,
      icon: <Redo />,
      title: `Redo`
    }
  }

  return (
    <MenuContainer open={open} top={top} left={left} ref={ref}>
      <trix-toolbar id={`${id}-toolbar`}>
        <ToolbarContainer>
          {Object.keys(actions)
            .map((key) => actions[key])
            ?.map(({ icon, attribute, action, callback, key }) => (
              <ToolbarItem
                key={attribute}
                data-trix-attribute={attribute}
                data-trix-action={action}
                data-trix-key={key}
                onClick={callback}
              >
                <ToolbarIconContainer>{icon}</ToolbarIconContainer>
              </ToolbarItem>
            ))}
        </ToolbarContainer>
      </trix-toolbar>
    </MenuContainer>
  )
}

export default forwardRef(Toolbar)
