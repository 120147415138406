import React from "react"
import {OverridableComponent} from "@mui/material/OverridableComponent"
import {SvgIconTypeMap} from "@mui/material/SvgIcon/SvgIcon"
import {
  FormatAlignLeft,
  Code,
  AvTimer,
  RemoveRedEye,
  Timeline,
  ViewInAr,
  HourglassEmpty
} from "@mui/icons-material"
import {
  TextBlock,
  CodeBlock,
  TimelineBlock,
  HiddenBlock,
  LineChartBlock,
  CuboidMeasurementBlock,
  CountdownBlock
} from "./components/Blocks"

export type Block = {
  name: string
  title: string
  description: string
  icon?: OverridableComponent<SvgIconTypeMap<{}, "svg">> & {
    muiName: string}
  component?: React.ElementType
  comingSoon?: boolean
}

export type Blocks = {
  [name: string]: Block
}

export const blocks: Blocks = {
  text: {
    name: "text",
    title: "Text",
    description: "Text component for general notes. Support rich text formatting",
    icon: FormatAlignLeft,
    component: TextBlock,
  },
  code: {
    name: "code",
    title: "Code",
    description: "For programming code snippets that need syntax highlighting",
    icon: Code,
    component: CodeBlock,
  },
  timeline: {
    name: "timeline",
    title: "Timeline",
    description: "Past and future notes are collapsed - only today's notes are shown",
    icon: AvTimer,
    component: TimelineBlock,
  },
  hidden: {
    name: "hidden",
    title: "Hidden",
    description: "Content is hidden until you click it, then it's hidden when you go idle",
    icon: RemoveRedEye,
    component: HiddenBlock,
  },
  lineChart: {
    name: "lineChart",
    title: "Line Chart",
    description: "Line graph with multiple sets of data, average lines and logging by camera or voice",
    icon: Timeline,
    component: LineChartBlock,
  },
  cuboidMeasurement: {
    name: "cuboidMeasurement",
    title: "Cuboid Measurement",
    description: "For width, height and depth measurements. Input by camera or voice",
    icon: ViewInAr,
    component: CuboidMeasurementBlock
  },
  countdown: {
    name: "countdown",
    title: "Countdown",
    description: "Count down to a specific date and time",
    icon: HourglassEmpty,
    component: CountdownBlock
  },
  recipe: {
    name: "recipe",
    title: "Recipe",
    description: "Recipe with ingredients, steps and timers",
    comingSoon: true
  },
  holiday: {
    name: "holiday",
    title: "Holiday Itinary",
    description: "Plan each step of your holiday with airport times, hotel bookings, activities and more",
    comingSoon: true
  },
}