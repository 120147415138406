import React, { useCallback, useEffect, useState, useRef } from "react"
import { Container } from "./RichTextInput.styled"
import { RichTextInputProps, Editor } from "./types"
import Trix from "trix"
import Autolinker from "autolinker"
import Toolbar from "./Toolbar"
import useToolbarPosition from "./hooks/useToolbarPosition"

Trix.config.blockAttributes.heading2 = { tagName: `h2` }
Trix.config.blockAttributes.paragraph = { tagName: `p` }
Trix.config.textAttributes.strikethrough = { tagName: `strike` }
Trix.config.textAttributes.underline = { tagName: `u` }
Trix.config.textAttributes.inlineCode = {
  tagName: `span`,
  style: {
    backgroundColor: `rgba(94, 127, 245, 0.05)`,
    border: `1px solid rgba(94, 127, 245, 0.4)`,
    padding: `0 3px`,
    borderRadius: `3px`,
    color: `#000`
  }
}

export const RichTextInput = ({
  onChange,
  defaultValue,
  id
}: RichTextInputProps) => {
  const [value, setValue] = useState<string>()
  const trixRef = useRef<(HTMLDivElement & { editor: Editor }) | null>(null)
  const toolbarRef = useRef<HTMLDivElement | null>(null)
  const [toolbarVisible, setToolbarVisible] = useState<boolean>(false)
  const { position: toolbarPosition, repositionToolbar } = useToolbarPosition({
    trixRef,
    toolbarRef
  })
  const [keySeries, setKeySeries] = useState<string[]>([])

  useEffect(() => {
    if (!defaultValue) return
    setValue(defaultValue)
  }, [])

  useEffect(() => {
    const editor = trixRef?.current?.editor
    if (!editor) return

    const position = editor.getPosition()
    if (
      position < 2 ||
      editor.attributeIsActive(`code`) ||
      editor.attributeIsActive(`heading1`)
    ) {
      return
    }
    let text = editor
      .getDocument()
      .toString()
      .substring(position - 4, position)
    const bullet = /\n[*-] /
    const number = /\n1\. /
    const code = /```\n/
    let type,
      offset = 0
    if (position < 4) {
      text = `\n` + text
    }

    if (bullet.test(text)) {
      offset = 2
      type = `bullet`
    } else if (number.test(text)) {
      offset = 3
      type = `number`
    } else if (code.test(text)) {
      offset = 4
      type = `code`
    }

    if (type) {
      editor.recordUndoEntry(`create block`)
      editor.setSelectedRange([position - offset, position])

      editor.deleteInDirection(`forward`)

      editor.activateAttribute(type)
    }
  }, [value])

  useEffect(() => {
    const editor = trixRef?.current?.editor
    if (!editor) return

    const text = editor.getDocument().toString()

    const match = /`[^`](.*?)`/.exec(text)

    if (!match) return

    const content = match[0]
    const contentWithoutTicks = content.substring(1, match[0].length - 1)

    editor.setSelectedRange([match.index, match.index + content.length])
    editor.deleteInDirection(`backward`)

    editor.insertString(contentWithoutTicks)
    editor.setSelectedRange([
      match.index,
      match.index + contentWithoutTicks.length
    ])

    editor.activateAttribute(`inlineCode`)
    editor.setSelectedRange([match.index + contentWithoutTicks.length])
    editor.deactivateAttribute(`inlineCode`)

    setTimeout(() => {
      const newValue = trixRef?.current?.innerHTML ?? ``
      setValue(newValue)
      onChange(newValue)
    }, 1500)
  }, [value, onChange])

  useEffect(() => {
    if (defaultValue === value) return
    const editor = trixRef?.current?.editor
    if (!editor || !trixRef.current || !defaultValue) return
    const cursorPos = editor?.getSelectedRange?.()
    trixRef.current.innerHTML = defaultValue
    setValue(defaultValue)
    editor?.setSelectedRange(cursorPos)
  }, [defaultValue])

  const handleChange = useCallback(
    (e) => {
      if ((e as any).target.trixId !== id) return
      const newValue = e?.target?.value ?? ``
      setValue(newValue)
      onChange(newValue)
      repositionToolbar()
    },
    [id, onChange, repositionToolbar]
  )

  const handlePaste = useCallback(
    (e) => {
      if ((e as any).target.trixId !== id) return
      navigator.clipboard.readText().then((pastedText) => {
        const matches = Autolinker.parse(pastedText, {})

        if (matches.length !== 0) {
          const element = trixRef.current as any
          const currentText = element.editor.getDocument().toString()
          const currentSelection = element.editor.getSelectedRange()
          const textWeAreInterestedIn = currentText.substring(
            0,
            currentSelection[0]
          )
          const startOfPastedText =
            textWeAreInterestedIn.lastIndexOf(pastedText)
          element.editor.recordUndoEntry(`Auto Link Paste`)
          element.editor.setSelectedRange([
            startOfPastedText,
            currentSelection[0]
          ])
          element.editor.activateAttribute(`href`, pastedText)
          element.editor.setSelectedRange(currentSelection)
        }
      })
    },
    [id]
  )

  const handleEditorClick = useCallback((e) => {
    if (e.target.tagName === `A`) {
      const win = window.open(e.target.href, `_blank`)
      if (win) win.focus()
    }
  }, [])

  const handlePointerUp = useCallback(
    (e) => {
      const closestTrixEditor = e.target.closest(`trix-editor`)
      const closestTrixToolbar = e.target.closest(`trix-toolbar`)

      if (closestTrixToolbar) return

      if (!closestTrixEditor || closestTrixEditor.trixId !== id) {
        setToolbarVisible(false)
        return
      }

      setToolbarVisible(true)
      repositionToolbar()
    },
    [repositionToolbar, id]
  )

  const handlePointerDown = useCallback(() => {
    setToolbarVisible(false)
  }, [])

  const handleKeyDown = useCallback(
    (e) => {
      if ((e as any).target.trixId === id) {
        setKeySeries((prevState) => [...prevState, e.key].slice(-5))
      }
      setToolbarVisible(false)
    },
    [id]
  )

  const handleSelection = useCallback(() => {
    repositionToolbar()
  }, [repositionToolbar])

  useEffect(() => {
    const trixElement = trixRef?.current

    trixElement?.addEventListener(`click`, handleEditorClick)
    document.addEventListener(`trix-change`, handleChange)
    document.addEventListener(`trix-paste`, handlePaste)
    document.addEventListener(`trix-selection-change`, handleSelection)
    trixElement?.addEventListener(`pointerdown`, handlePointerDown)
    document.addEventListener(`pointerup`, handlePointerUp)
    document.addEventListener(`keydown`, handleKeyDown)

    return () => {
      trixElement?.removeEventListener(`click`, handleEditorClick)
      document.removeEventListener(`trix-change`, handleChange)
      document.removeEventListener(`trix-paste`, handlePaste)
      document.removeEventListener(`trix-selection-change`, handleSelection)
      trixElement?.removeEventListener(`pointerdown`, handlePointerDown)
      document.removeEventListener(`pointerup`, handlePointerUp)
      document.removeEventListener(`keydown`, handleKeyDown)
    }
  }, [
    handleChange,
    handleEditorClick,
    handlePaste,
    handlePointerDown,
    handlePointerUp,
    handleSelection,
    trixRef,
    handleKeyDown
  ])

  return (
    <Container>
      <input type="hidden" id={id} value={defaultValue} />
      <Toolbar
        ref={toolbarRef}
        id={id}
        open={toolbarVisible}
        editor={trixRef?.current?.editor}
        key={`${id}-editor`}
        {...toolbarPosition}
      />
      <trix-editor
        input={id}
        trix-id={id}
        ref={trixRef}
        key={`${id}-toolbar`}
        toolbar={`${id}-toolbar`}
      />
    </Container>
  )
}

export default RichTextInput
