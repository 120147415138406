// @ts-nocheck

import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { LineChartBlockProps, DataItem, LineChartData } from "./types"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale
} from "chart.js"
import type { ChartOptions } from "chart.js"
import zoomPlugin from "chartjs-plugin-zoom"
import "chartjs-adapter-date-fns"
import { enGB } from "date-fns/locale"
import { subDays, subHours, addHours, format, addDays } from "date-fns"
import Menu from "./Menu"
import useMediaQuery from "../../../hooks/useMediaQuery"
import { LineChart, Container } from "./LineChartBlock.styled"
import regression from 'regression';
import _ from 'lodash';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  zoomPlugin,
  TimeScale
)

const lineColours = [
  `rgb(94, 127, 245)`,
  `rgb(255, 99, 132)`,
  `rgb(255, 159, 64)`,
  `rgb(153, 102, 255)`,
  `rgb(255, 205, 86)`,
  `rgb(54, 162, 235)`,
  `rgb(75, 192, 192)`,
  `rgb(49, 196, 72)`,
  `rgb(238, 14, 1)`
]

function addOpacityToColour(colour, opacity) {
  return colour.replace(`)`, `, ${opacity})`).replace(`rgb`, `rgba`)
}

export const LineChartBlock = ({
  onChange,
  defaultValue
}: LineChartBlockProps) => {
  const chartRef = useRef<ChartJS>(null)
  const [chartKey, setChartKey] = useState(0)
  const [selectedPoint, setSelectedPoint] = useState<{
    index: number
    datasetLabel: string
  }>()
  const [clickEvent, setClickEvent] = useState<any>()
  const [showResetButton, setShowResetButton] = useState(false)

  const datasets = useMemo(() => defaultValue ?? [], [defaultValue])

  const isMobile = useMediaQuery(`(max-width: 768px)`)

  const allDates = useMemo(() => {
    if (!defaultValue) return

    return defaultValue
      .map((dataset) => dataset.data)
      .flat()
      .map((item) => +format(new Date(item.x), `t`))
  }, [defaultValue])

  const earliestDate = useMemo(() => {
    if (!defaultValue) return
    if (!allDates) return

    const min = Math.min(...allDates)

    if (!min || min === Infinity)
      return format(new Date(), `yyyy-MM-dd HH:mm:ss`)

    return format(new Date(min * 1000), `yyyy-MM-dd HH:mm:ss`)
  }, [allDates, defaultValue])

  const firstXValue = useMemo(() => {
    if (!defaultValue?.length) return undefined
    const firstXValues = defaultValue.map((dataset) => {
      const firstPoint = dataset?.data?.[0]
      if (!(firstPoint as any)?.x) return 0
      if ((dataset as any).hidden) return 0
      return +format(new Date((firstPoint as any).x), `t`)
    })
    if (!firstXValues?.length) return undefined
    return Math.min(...firstXValues)
  }, [chartRef.current?.data])

  const lastXValue = useMemo(() => {
    if (!defaultValue?.length) return undefined
    const lastXValues = defaultValue.map((dataset) => {
      const lastPoint = dataset?.data?.[dataset.data.length - 1]
      if (!(lastPoint as any)?.x) return 0
      if ((dataset as any).hidden) return 0
      return +format(new Date((lastPoint as any).x), `t`)
    })
    if (!lastXValues?.length) return undefined
    return Math.max(...lastXValues)
  }, [chartRef.current?.data])

  const latestDate = useMemo(() => {
    if (!defaultValue) return
    if (!allDates) return

    const max = Math.max(...allDates)

    if (!max || max === -Infinity)
      return format(new Date(), `yyyy-MM-dd HH:mm:ss`)

    return format(new Date(max * 1000), `yyyy-MM-dd HH:mm:ss`)
  }, [allDates, defaultValue])

  const isDataSpanLessThanOneMonth = useMemo(() => {
    if (!defaultValue) return false
    if (!earliestDate) return false
    if (!latestDate) return false

    const differenceInDays = Math.floor(
      (new Date(latestDate).getTime() - new Date(earliestDate).getTime()) /
        (1000 * 3600 * 24)
    )

    return differenceInDays < 30
  }, [defaultValue, earliestDate, latestDate])

  const datasetLabels = defaultValue?.map((item) => {
    return item.label
  })

  const datasetLabelsAndColours = useMemo(() => {
    return (datasetLabels ?? []).map((label, index) => ({
      label,
      colour: lineColours[index % lineColours.length]
    }))
  }, [datasetLabels])

  const handleNewEntry = useCallback(
    (newEntry: DataItem & { dataset: string }) => {
      const isDatasetExisting = defaultValue?.find(
        (item) => item.label === newEntry.dataset
      )

      if (isDatasetExisting) {
        const newDatasets = (defaultValue ?? []).map((dataset) => {
          if (dataset.label === newEntry.dataset) {
            return {
              ...dataset,
              data: [
                ...dataset.data,
                {
                  x: newEntry.x,
                  y: newEntry.y,
                  comments: newEntry.comments
                }
              ].sort((a, b) => {
                return new Date(a.x).getTime() - new Date(b.x).getTime()
              })
            }
          }
          return dataset
        })

        onChange?.(newDatasets)
      }

      if (!isDatasetExisting) {
        const newDatasets = [
          ...(defaultValue ?? []),
          {
            label: newEntry.dataset,
            data: [
              {
                x: newEntry.x,
                y: newEntry.y,
                comments: newEntry.comments
              }
            ]
          }
        ]

        onChange?.(newDatasets)
      }

      setChartKey(Math.random())

      updateAverages()
    },
    [defaultValue, onChange]
  )

  const handleRemoveEntry = useCallback(() => {
    if (!defaultValue) return

    const newDataset = (defaultValue ?? []).map((dataset) => {
      if (dataset.label === selectedPoint?.datasetLabel) {
        return {
          ...dataset,
          data: dataset.data.filter(
            (item, index) => index !== selectedPoint?.index
          )
        }
      }
      return dataset
    })

    onChange?.(newDataset)
    setSelectedPoint(undefined)
    setChartKey(Math.random())
  }, [onChange, selectedPoint, defaultValue])

  const handleRemoveDataset = useCallback(() => {
    if (!defaultValue) return
    if (!selectedPoint?.datasetLabel) return

    const newDataset = (defaultValue ?? []).filter(
      (item, index) => item.label !== selectedPoint?.datasetLabel
    )

    onChange?.(newDataset)
    setSelectedPoint(undefined)
    setChartKey(Math.random())
  }, [onChange, selectedPoint, defaultValue])

  const handleResetButtonClick = useCallback(() => {
    if (!chartRef.current) return

    if (!chartRef?.current?.options?.scales?.y) return
    if (!chartRef?.current?.options?.scales?.x) return

    chartRef.current.options.scales.x.min = undefined
    chartRef.current.options.scales.x.max = undefined

    setChartKey(Math.random())
    setShowResetButton(false)
    setTimeout(() => {
      updateAverages()
    })
  }, [])

  useEffect(() => {
    setChartKey(Math.random())
  }, [isMobile])

  const handleClick = useCallback((event) => {
    setClickEvent(event)
  }, [])

  useEffect(() => {
    if (!chartRef.current) return
    if (!clickEvent) return

    const exactPoint = chartRef.current.getElementsAtEventForMode(
      clickEvent,
      `nearest`,
      { intersect: true },
      false
    )

    const datasetIndex = exactPoint?.[0]?.datasetIndex

    setSelectedPoint({
      index: exactPoint?.[0]?.index,
      datasetLabel: chartRef.current?.data.datasets?.[datasetIndex]?.label ?? ""
    })
  }, [clickEvent])

  const updateAverages = useCallback(() => {
    if (!chartRef.current) return
    const datasets = chartRef.current.data.datasets.filter(
      (dataset) => !dataset?.label?.match(/Average$/)
    )

    datasets.forEach((dataset: any, index) => {

      if (!chartRef.current) return

      const visibleData = (dataset?.data ?? []).filter(
        (point: any) => {
          if (!point?.x) return false
          if (!chartRef.current) return false
          return (
            +format(addDays(new Date(point.x), 3), "t") * 1000 >=
              chartRef.current.scales.x.min &&
            +format(subDays(new Date(point.x), 3), "t") * 1000 <=
              chartRef.current.scales.x.max
          )
        }
      )

      const regressionData = visibleData.map(({x, y}) => {
        return [+format(new Date(x), `t`), +y]
      })

      const result = regression.logarithmic(regressionData)

      const datasetCount = defaultValue?.length ?? 0

      const chartData = result.points.map(([x, y]) => ({
        x: format(new Date(x * 1000), `yyyy-MM-dd HH:mm:ss`),
        y: y.toString()
      }))

      chartRef.current.data.datasets[datasetCount + index] = {
        ...(chartRef?.current?.data?.datasets?.[datasetCount + index] ?? {}),
        label: `${dataset.label} Average`,
        data: visibleData.length > 10 ? chartData : [],
        pointRadius: 0,
        borderColor: addOpacityToColour(
          lineColours[index % lineColours.length],
          0.1
        ),
        borderWidth: 15
      }
    })
    chartRef.current.update()
  }, [chartRef.current?.data.datasets])

  const updateLineStyle = useCallback(() => {
    if (!chartRef?.current) return

    const datasets = chartRef.current.data.datasets.filter(
      (dataset) => !dataset?.label?.match(/Average$/)
    )

    const visiblePoints = datasets.reduce((acc, dataset) => {
      const pointCount = (dataset?.data ?? []).filter((point: any) => {
        if (!point?.x) return false
        if (!chartRef.current) return false
        return (
          +format(new Date(point.x), "t") * 1000 >=
            chartRef.current.scales.x.min &&
          +format(new Date(point.x), "t") * 1000 <=
            chartRef.current.scales.x.max
        )
      }).length

      return acc + pointCount
    }, 0)

    datasets.forEach((dataset: any) => {
      dataset.pointRadius = visiblePoints > 100 ? 0 : 4
      dataset.borderWidth = visiblePoints > 100 ? 1 : 3
    })

    updateAverages()
    chartRef.current.update()
  }, [chartRef.current?.data.datasets])

  const handleZoomFitClick = useCallback(() => {
    if (!chartRef.current) return
    if (!earliestDate) return
    if (!latestDate) return

    if (!chartRef?.current?.options?.scales?.y) return
    if (!chartRef?.current?.options?.scales?.x) return

    chartRef.current.options.scales.y.min = undefined
    chartRef.current.options.scales.y.max = undefined

    chartRef.current.options.scales.x.min = undefined
    chartRef.current.options.scales.x.max = undefined

    chartRef.current.update()

    updateLineStyle()

    setShowResetButton(true)
  }, [earliestDate, latestDate, updateLineStyle])

  const handleDatasetButtonClick = useCallback(({ datasetsToShow }) => {
    if (!chartRef.current) return

    chartRef.current.data.datasets.forEach((dataset) => {
      dataset.hidden = !datasetsToShow.includes(dataset.label)

      if (!dataset?.label) return

      if (dataset.label.match(/Average$/)) {
        dataset.hidden = !datasetsToShow.includes(
          dataset.label.replace(/ Average$/, "")
        )
      }
    })

    if (!chartRef?.current?.options?.scales?.y) return
    if (!chartRef?.current?.options?.scales?.x) return

    chartRef.current.options.scales.y.min = undefined
    chartRef.current.options.scales.y.max = undefined

    chartRef.current.options.scales.x.min = undefined
    chartRef.current.options.scales.x.max = undefined

    chartRef.current.update()

    updateLineStyle()
  }, [firstXValue, lastXValue])

  const handleDataEditFormSubmit = useCallback(
    (data) => {
      onChange(data)
      chartRef.current.update()
    },
    []
  )

  const minScale = useMemo(() => {
    if (!earliestDate) return
    if (!latestDate) return

    if (isDataSpanLessThanOneMonth) {
      return format(subHours(new Date(earliestDate), 1), `yyyy-MM-dd HH:mm:ss`)
    } else {
      return format(subDays(new Date(latestDate), 14), "yyyy-MM-dd HH:mm:ss")
    }
  }, [earliestDate, isDataSpanLessThanOneMonth, latestDate])

  const maxScale = useMemo(() => {
    if (!latestDate) return

    if (isDataSpanLessThanOneMonth) {
      return format(addHours(new Date(latestDate), 1), `yyyy-MM-dd HH:mm:ss`)
    } else {
      return format(
        addHours(new Date(latestDate ?? undefined), 2),
        "yyyy-MM-dd HH:mm:ss"
      )
    }
  }, [latestDate, isDataSpanLessThanOneMonth])

  useEffect(() => {
    if (!chartRef.current) return
    chartRef.current.canvas.style.touchAction = `pan-y`;
  }, [])
  
  useEffect(() => {
    const hasAverages = chartRef.current?.data?.datasets?.some(
      (dataset) => dataset?.label?.match(/Average$/)
    )
    if (!hasAverages) {
      setTimeout(() => {
        updateAverages()
      })
    }
  }, [updateAverages, chartRef.current?.data])

  const options: ChartOptions<'line'> = useMemo(
    () => ({
      animation: false,
      scales: {
        x: {
          ticks: {
            autoSkip: true,
            autoSkipPadding: 50,
            maxRotation: 0,
            minRotation: 0
          },
          grid: {
            display: false
          },
          adapters: {
            date: {
              locale: enGB
            }
          },
          type: `time`,
          time: {
            displayFormats: {
              hour: `E hbbb`,
              minute: "h:mm a",
              month: "MMM yyyy"
            }
          },
          min: minScale,
          max: maxScale 
        },
        y: {
          position: `right`,
          grid: {
            display: true
          },
          ticks: {
            precision: 0
          }
        }
      },
      plugins: {
        legend: {
          display: false
        },
        zoom: {
          limits: {
            x: {
              min: firstXValue ? firstXValue * 1000 : undefined,
              max: lastXValue ? lastXValue * 1000 : undefined
            },
          },
          zoom: {
            scaleMode: "x",
            mode: `x`,
            pinch: {
              enabled: true
            },
            wheel: {
              enabled: true
            },
            onZoom({ chart }) {
              setShowResetButton(true)
              updateLineStyle()
            }
          },
          pan: {
            enabled: true,
            mode: `x`,
            threshold: 30,
            onPan: ({ chart }) => {
              setShowResetButton(true)
              updateLineStyle()

              const datasets = chart.data.datasets.filter(
                (dataset) => !dataset?.label?.match(/Average$/)
              )

              const visiblePoints = datasets.reduce((acc, dataset) => {
                const pointCount = (dataset?.data ?? []).filter(
                  (point: any) => {
                    if (!point?.x) return false
                    if (!chartRef.current) return false
                    return (
                      +format(new Date(point.x), "t") * 1000 >=
                        chartRef.current.scales.x.min &&
                      +format(new Date(point.x), "t") * 1000 <=
                        chartRef.current.scales.x.max
                    )
                  }
                ).length

                return acc + pointCount
              }, 0)

              datasets.forEach((dataset: any) => {
                dataset.pointRadius = visiblePoints > 100 ? 0 : 4
                dataset.borderWidth = visiblePoints > 100 ? 1 : 3
              })

              chart.update()
            }
          }
        },
        tooltip: {
          caretPadding: 20,
          callbacks: {
            footer: (tooltipItems: any) => {
              if (tooltipItems[0].raw.comments !== ``) {
                return tooltipItems[0].raw.comments
              }
            }
          }
        }
      }
    }),
    [maxScale, minScale]
  )

  const chartData: LineChartData = useMemo(
    () => ({
      datasets: [
        ...datasets.map((dataset, index) => ({
          ...dataset,
          borderColor: lineColours[index % lineColours.length],
          backgroundColor: lineColours[index % lineColours.length],
          borderWidth: 3,
          pointRadius: 4,
          pointBackgroundColor: function (context) {
            const dataIndex = context.dataIndex
            if (!(context?.dataset?.data?.[dataIndex] as any)?.comments)
              return `rgba(255,255,255)`
            const { comments } = context.dataset.data[dataIndex] as any
            return comments !== ``
              ? lineColours[index % lineColours.length]
              : `rgba(255,255,255)`
          },
          pointBorderColor: lineColours[index % lineColours.length],
          pointHoverRadius: 6,
          pointHoverBackgroundColor: lineColours[index % lineColours.length],
          pointHoverBorderColor: lineColours[index % lineColours.length]
        }))
      ]
    }),
    [datasets]
  )

  return (
    <>
      <Container>
        <LineChart
          border
          height={isMobile ? 300 : undefined}
          key={chartKey}
          ref={chartRef}
          onClick={handleClick}
          options={datasets.length > 0 ? options : undefined}
          data={chartData}
        />
        <Menu
          showResetButton={showResetButton}
          datasets={datasetLabelsAndColours}
          data={defaultValue}
          showRemoveButton={!!selectedPoint}
          onSubmit={handleNewEntry}
          onRemovePoint={handleRemoveEntry}
          onResetButtonClick={handleResetButtonClick}
          onZoomFitClick={handleZoomFitClick}
          onDatasetClick={handleDatasetButtonClick}
          onRemoveDataset={handleRemoveDataset}
          onDataEditFormSubmit={handleDataEditFormSubmit}
        />
      </Container>
    </>
  )
}

export default LineChartBlock
