import styled from "styled-components"
import PaperBase from "@mui/material/Paper"
import InputBase from "@mui/material/InputBase"
import IconButtonBase from "@mui/material/IconButton"

export const Paper = styled(PaperBase)`
  && {
    padding: 2px 4px;
    display: flex;
    align-items: center;
    width: 400px;
  }
` as typeof PaperBase

export const Input = styled(InputBase)`
  margin-left: 20px;
  flex: 1;
`

export const IconButton = styled(IconButtonBase)<{ $active: boolean }>`
  padding: 10;
  * {
    fill: ${({ $active }) => ($active ? "#5e7ff5" : "#eee")};
  }
`
