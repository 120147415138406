import styled, { keyframes, css } from "styled-components"
import IconButton from "@mui/material/IconButton"

export const EditorComponentsContainer = styled.div``

export const View = styled.div`
  color: rgb(56, 56, 56);
  font-family: -apple-system, system-ui, "Segoe UI", Roboto, Oxygen, Ubuntu,
    Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  line-height: 1.57143em;
  padding: 15px;
`

export const ComponentToolbar = styled.div`
  height: 30px;
  width: 100%;
  background-color: #fafafa;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  * {
    fill: #5e7ff5;
  }
`

export const DragIconButton = styled(IconButton)`
  && {
    cursor: grab;
  }
`

export const DeleteIconButton = styled(IconButton)`
  && {
    cursor: pointer;
  }
`

export const ConfirmDeleteIconButton = styled(IconButton)`
  && {
    cursor: pointer;
    background-color: #96e283;
    &:hover {
      background-color: #7dce69;
    }
    path {
      fill: #fff;
    }
  }
`

export const EditorComponentContainer = styled.div<{configuring: boolean}>`
  background-color: #fff;
  padding: ${({configuring}) => configuring ? '20px' : '7px'};
`
