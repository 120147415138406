import styled from "styled-components"
import IconButton from "@mui/material/IconButton"
import Grid from "@mui/material/Grid"
import { up } from "styled-breakpoints"

export const ColourSvgContainer = styled.div`
  display: inline-block;
  width: 30px;
  height: 30px;
`


export const ConfirmDeleteIconButton = styled(IconButton)`
  && {
    cursor: pointer;
    background-color: #96e283;
    &:hover {
      background-color: #7dce69;
    }
    path {
      fill: #fff;
    }
  }
`

export const DatasetIconContainer = styled.div`
  display: inline-block;
`

export const TextAlign = styled.div<{ align: string }>`
  text-align: ${({ align }) => align};
`

export const DatasetLabel = styled.div`
  display: inline-block;
  ${up("md")} {
    cursor: pointer;
  }
`

export const IconButtonLabel = styled.label`
  white-space: nowrap;
  &:hover {
    button {
      background-color: rgba(0, 0, 0, 0.04);
    }
  }
`

export const MenuGridContainer = styled(Grid)<{ datasetCount: number }>`
  && {
    margin-top: 10px;
    flex-wrap: nowrap;
  }
`

export const DatasetIconGrid = styled(Grid)``

export const ButtonGrid = styled(Grid)`
  white-space: nowrap;
  padding-top: 4px;
  padding-left: 30px;

  ${up("md")} {
    padding-left: 64px;
  }
`