import React, { useContext, useCallback, useMemo } from "react"
import { Tag } from "./MobileMenu.styled"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import { NotesContext } from "../../contexts"
import { useHistory } from "react-router-dom"
import NewNoteInput from "../NewNoteInput"
import SettingsDialogue from "../SettingsDialogue"
import { useParams } from "react-router-dom"
import { orderBy } from "lodash"

export const MobileMenu = () => {
  const { notes } = useContext(NotesContext) ?? { notes: {} }
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const history = useHistory()
  const params = useParams<{ id: string }>()

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleItemClick = useCallback(
    (id: string) => () => {
      setAnchorEl(null)
      history.push(`/${id}`)
    },
    [history]
  )

  const notesSorted = useMemo(
    () =>
      orderBy(
        Object.keys(notes ?? {}).map((id) => ({ id, ...(notes ?? {})?.[id] })),
        `id`
      ),
    [notes]
  )

  return (
    <>
      <Tag onClick={handleClick} />
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: 300
          }
        }}
      >
        {notesSorted &&
          notesSorted.map(({ id }) => (
            <MenuItem
              key={id}
              onClick={handleItemClick(id)}
              selected={id === params?.id}
            >
              {notes?.[id]?.title ?? id}
            </MenuItem>
          ))}
        <MenuItem onKeyDown={(e) => e.stopPropagation()}>
          <NewNoteInput /> <SettingsDialogue />
        </MenuItem>
      </Menu>
    </>
  )
}

export default MobileMenu
