import { useState, useCallback } from "react"

export default ({ trixRef, toolbarRef }) => {
  const [position, setPosition] = useState<{ top: number; left: number }>({
    top: 0,
    left: 0
  })
  const isTouchDevice = matchMedia(`(hover: none)`).matches

  const repositionToolbar = useCallback(() => {
    if (!trixRef?.current || !toolbarRef?.current) return

    const trixElement = trixRef?.current
    const selectedRange = trixElement.editor.getSelectedRange()
    const selectionCollapsed = selectedRange[0] === selectedRange[1]
    const scrollOffsetY = window.scrollY

    const cursorPos = trixElement?.editor.getClientRectAtPosition(
      selectedRange[0]
    )

    const positionAboveCursor = {
      top: (cursorPos?.top ?? 0) - 70 + scrollOffsetY,
      left: cursorPos?.left ?? 0
    }

    const positionBelowEditor = {
      top: trixElement.offsetTop + trixElement.offsetHeight + 15,
      left: trixElement.offsetLeft
    }

    let newPosition = positionAboveCursor

    if (selectionCollapsed || isTouchDevice) {
      newPosition = positionBelowEditor
    }

    const positionTooHigh = newPosition.top < 0
    const positionTooLeft =
      newPosition.left + toolbarRef.current?.offsetWidth > window.innerWidth

    if (positionTooHigh) {
      newPosition.top = positionBelowEditor.top
    }
    if (positionTooLeft) {
      newPosition.left = window.innerWidth - toolbarRef.current?.offsetWidth
    }

    setPosition(newPosition)
  }, [isTouchDevice, toolbarRef, trixRef])

  return { position, repositionToolbar }
}
