import styled from "styled-components"

export const Container = styled.div`
  trix-editor {
    width: 100%;
    min-height: 1rem;
    outline: none;
  }
  h1,
  h2 {
    margin: 0;
  }
  hr {
    border: 0;
    border-top: 1px solid #eeeeee;
  }
  a {
    cursor: pointer;
    color: #017ae4;
  }
  pre {
    background-color: rgba(94, 127, 245, 0.05);
    border: 1px solid rgba(94, 127, 245, 0.4);
    width: fit-content;
    display: block;
    padding: 5px 7px;
    border-radius: 3px;
    color: #000;
    font-family: inherit;
    overflow-x: auto;
  }
`
