import React, { useMemo, useRef, useEffect, useState } from "react"
import { CuboidMeasurementBlockProps } from "./types"
import styled from "styled-components"
import ValueInput from "../../ValueInput"
import useMediaQuery from "../../../hooks/useMediaQuery"

const drawCube = (x, y, wx, wy, h, ctx) => {
  const offsetY = (y - h - (wx * 0.5 + wy * 0.5)) * -1
  const offsetX = (x - wx) * -1

  // left face
  ctx.beginPath()
  ctx.moveTo(x + offsetX, y + offsetY)
  ctx.lineTo(x - wx + offsetX, y - wx * 0.5 + offsetY)
  ctx.lineTo(x - wx + offsetX, y - h - wx * 0.5 + offsetY)
  ctx.lineTo(x + offsetX, y - h * 1 + offsetY)
  ctx.closePath()
  ctx.fillStyle = `#3853b2`
  ctx.strokeStyle = `#3853b2`
  ctx.stroke()
  ctx.fill()

  // right face
  ctx.beginPath()
  ctx.moveTo(x + offsetX, y + offsetY)
  ctx.lineTo(x + wy + offsetX, y - wy * 0.5 + offsetY)
  ctx.lineTo(x + wy + offsetX, y - h - wy * 0.5 + offsetY)
  ctx.lineTo(x + offsetX, y - h * 1 + offsetY)
  ctx.closePath()
  ctx.fillStyle = `#829dfd`
  ctx.strokeStyle = `#829dfd`
  ctx.stroke()
  ctx.fill()

  // top face
  ctx.beginPath()
  ctx.moveTo(x + offsetX, y - h + offsetY)
  ctx.lineTo(x - wx + offsetX, y - h - wx * 0.5 + offsetY)
  ctx.lineTo(x - wx + wy + offsetX, y - h - (wx * 0.5 + wy * 0.5) + offsetY)
  ctx.lineTo(x + wy + offsetX, y - h - wy * 0.5 + offsetY)
  ctx.closePath()
  ctx.fillStyle = `#5e7ff5`
  ctx.strokeStyle = `#5e7ff5`
  ctx.stroke()
  ctx.fill()
}

const resizeCanvasToFit = (x, y, wx, wy, h, canvas) => {
  const offsetY = (y - h - (wx * 0.5 + wy * 0.5)) * -1
  const offsetX = (x - wx) * -1
  const topHeight = y - h - (wx * 0.5 + wy * 0.5) + offsetY
  const leftHeight = y + offsetY
  const totalHeight = topHeight + leftHeight + 5

  const mostLeft = x - wx + offsetX
  const mostRight = x + wy + offsetX
  const totalWidth = mostRight + mostLeft

  canvas.width = totalWidth
  canvas.height = totalHeight
}

const calculateMidPoint = (x1, y1, x2, y2) => {
  return {
    x: (x1 + x2) / 2,
    y: (y1 + y2) / 2
  }
}

const Container = styled.div`
  position: relative;
  margin-left: 50px;
`

const Label = styled.div<{
  top: number
  left: number
  width?: number
  align?: string
}>`
  position: absolute;
  overflow: visible;
  top: ${({ top }) => top}px;
  left: ${({ left }) => left}px;
  ${({ width }) =>
    width &&
    `
    width: ${width}px;
  `}
  ${({ align }) =>
    align &&
    `
    text-align: ${align};
  `}
`

export const CuboidMeasurementBlock = ({
  onChange,
  defaultValue
}: CuboidMeasurementBlockProps) => {
  const [widthInputVisible, setWidthInputVisible] = useState(false)
  const [lengthInputVisible, setLengthInputVisible] = useState(false)
  const [depthInputVisible, setDepthInputVisible] = useState(false)
  const [widthValue, setWidthValue] = useState(defaultValue?.width ?? `100cm`)
  const [lengthValue, setLengthValue] = useState(
    defaultValue?.length ?? `200cm`
  )
  const [depthValue, setDepthValue] = useState(defaultValue?.depth ?? `300cm`)

  const canvasRef = useRef<HTMLCanvasElement>(null)

  const isMobile = useMediaQuery(`(max-width: 768px)`)

  const canvasWidth = 500
  const canvasHeight = 350

  const cubePositionX = canvasWidth / 2
  const cubePositionY = canvasHeight

  let sizeX = parseFloat(defaultValue?.width ?? `100cm`)
  let sizeY = parseFloat(defaultValue?.length ?? `200cm`)
  let sizeZ = parseFloat(defaultValue?.depth ?? `300cm`)

  // resize sizeX, sizeY and sizeZ proportionally so the biggest is 300
  const maxWidth = isMobile ? 100 : 300
  const biggest = Math.max(sizeX, sizeY, sizeZ)
  const ratio = maxWidth / biggest
  sizeX = sizeX * ratio
  sizeY = sizeY * ratio
  sizeZ = sizeZ * ratio

  const x = cubePositionX
  const y = cubePositionY
  const wx = sizeX
  const wy = sizeY
  const h = sizeZ
  const offsetY = (y - h - (wx * 0.5 + wy * 0.5)) * -1
  const offsetX = (x - wx) * -1

  useEffect(() => {
    if (!canvasRef.current) return

    const canvas = canvasRef.current

    const ctx = canvas.getContext(`2d`)

    function draw() {
      if (!ctx) return
      ctx.clearRect(0, 0, canvas.width, canvas.height)

      resizeCanvasToFit(
        cubePositionX,
        cubePositionY,
        sizeX,
        sizeY,
        sizeZ,
        canvas
      )
      drawCube(cubePositionX, cubePositionY, sizeX, sizeY, sizeZ, ctx)
    }

    requestAnimationFrame(draw)
  }, [cubePositionX, cubePositionY, sizeX, sizeY, sizeZ])

  const handleWidthInputBlur = (e) => {
    if (e.currentTarget.contains(e.relatedTarget)) return

    setWidthInputVisible(false)
    if (!widthValue || widthValue === ``) return
    onChange?.({
      ...defaultValue,
      width: widthValue
    })
  }

  const handlLengthInputBlur = (e) => {
    if (e.currentTarget.contains(e.relatedTarget)) return

    setLengthInputVisible(false)
    if (!lengthValue || lengthValue === ``) return
    onChange?.({
      ...defaultValue,
      length: lengthValue
    })
  }

  const handlDepthInputBlur = (e) => {
    if (e.currentTarget.contains(e.relatedTarget)) return

    setDepthInputVisible(false)
    if (!depthValue || depthValue === ``) return
    onChange?.({
      ...defaultValue,
      depth: depthValue
    })
  }

  const depthLabelPosition = useMemo(
    () => ({
      top: y - wy * 0.5 - h / 2 + offsetY,
      left: cubePositionX + sizeY + offsetX + 10
    }),
    [y, wy, h, offsetY, cubePositionX, sizeY, offsetX]
  )

  const handleWidthKeyPress = (e) => {
    if (e.key === `Enter`) {
      setWidthInputVisible(false)
      if (!widthValue || widthValue === ``) return
      onChange?.({
        ...defaultValue,
        width: widthValue
      })
    }
  }

  const handleLengthKeyPress = (e) => {
    if (e.key === `Enter`) {
      setLengthInputVisible(false)
      if (!lengthValue || lengthValue === ``) return
      onChange?.({
        ...defaultValue,
        length: lengthValue
      })
    }
  }

  const handleDepthKeyPress = (e) => {
    if (e.key === `Enter`) {
      setDepthInputVisible(false)
      if (!depthValue || depthValue === ``) return
      onChange?.({
        ...defaultValue,
        depth: depthValue
      })
    }
  }

  const widthLabelPosition = useMemo(
    () => ({
      top: calculateMidPoint(x, y, x - wx, y - wx * 0.5).y + offsetY,
      left: calculateMidPoint(x, y, x - wx, y - wx * 0.5).x - 50 + offsetX - 10
    }),
    [x, y, wx, offsetY, offsetX]
  )

  const lengthLabelPosition = useMemo(
    () => ({
      top: calculateMidPoint(x, y, x + wy, y - wy * 0.5).y + offsetY,
      left: calculateMidPoint(x, y, x + wy, y - wy * 0.5).x + offsetX + 5
    }),
    [x, y, wy, offsetY, offsetX]
  )

  return (
    <Container>
      <Label {...widthLabelPosition} width={50} align="right">
        {!widthInputVisible && (
          <span onClick={() => setWidthInputVisible(true)}>
            {widthValue && widthValue !== `` ? widthValue : `100cm`}
          </span>
        )}
        {widthInputVisible && (
          <div style={{ width: 200 }} onBlur={handleWidthInputBlur}>
            <ValueInput
              inputProps={{
                value: widthValue,
                autoFocus: true,
                onKeyPress: handleWidthKeyPress
              }}
              onChange={setWidthValue}
            />
          </div>
        )}
      </Label>
      <Label {...lengthLabelPosition}>
        {!lengthInputVisible && (
          <span onClick={() => setLengthInputVisible(true)}>
            {lengthValue && lengthValue !== `` ? lengthValue : `200cm`}
          </span>
        )}
        {lengthInputVisible && (
          <div onBlur={handlLengthInputBlur}>
            <ValueInput
              inputProps={{
                value: lengthValue,
                autoFocus: true,
                onKeyPress: handleLengthKeyPress
              }}
              onChange={setLengthValue}
            />
          </div>
        )}
      </Label>
      <Label {...depthLabelPosition}>
        {!depthInputVisible && (
          <span onClick={() => setDepthInputVisible(true)}>
            {depthValue && depthValue !== `` ? depthValue : `300cm`}
          </span>
        )}
        {depthInputVisible && (
          <div onBlur={handlDepthInputBlur}>
            <ValueInput
              inputProps={{
                value: depthValue,
                autoFocus: true,
                onKeyPress: handleDepthKeyPress
              }}
              onChange={setDepthValue}
            />
          </div>
        )}
      </Label>
      <canvas width="500" height="500" ref={canvasRef} />
    </Container>
  )
}

export default CuboidMeasurementBlock
