import React, { useContext, useCallback, useState } from "react"
import { NotesContext } from "../../contexts"
import CheckIcon from "@mui/icons-material/CheckCircleOutline"
import { Paper, Input, IconButton } from "./NewNoteInput.styled"
import { paramCase } from "change-case"
import { useHistory } from "react-router-dom"

export const NewNoteInput = () => {
  const { notes, setNotes } = useContext(NotesContext) ?? { notes: {} }
  const history = useHistory()
  const [newNote, setNewNote] = useState(``)

  const createNote = useCallback(() => {
    if (!newNote) return
    const key = paramCase(newNote)
    const newNotes = {
      ...notes,
      ...{ [key]: { title: newNote, content: [] } }
    }
    setNotes?.(newNotes)
    history.push(`/${key}`)
    setNewNote(``)
  }, [history, newNote, notes, setNotes])

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e?.target?.value ?? ``
    setNewNote(value)
  }, [])

  const handleCreateClick = useCallback(() => {
    createNote()
  }, [createNote])

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e?.keyCode === 13) {
        e.preventDefault()
        createNote()
      }
    },
    [createNote]
  )

  return (
    <Paper component="form">
      <Input
        placeholder="Create note"
        onChange={handleChange}
        value={newNote}
        onKeyDown={handleKeyDown}
      />
      <IconButton
        color="primary"
        onClick={handleCreateClick}
        $active={newNote.length > 0}
        size="large"
      >
        <CheckIcon />
      </IconButton>
    </Paper>
  )
}

export default NewNoteInput
