import React, {
  useMemo,
  useState,
  useCallback,
  useEffect,
  ChangeEvent
} from "react"
import {
  ItemContainer,
  Date,
  TextInputContainer,
  LoadPrevious,
  AddNew,
  Contract,
  Container,
  DatePicker
} from "./TimelineBlock.styled"
import {
  format,
  fromUnixTime,
  differenceInCalendarDays,
  isToday,
  differenceInDays
} from "date-fns"
import { TimelineBlockProps } from "./types"
import { orderBy } from "lodash"
import { Grid } from "@mui/material"
import RichTextInput from "../../RichTextInput"
import IconButton from "@mui/material/IconButton"
import {
  KeyboardArrowUp,
  AddCircleOutline,
  UnfoldLess
} from "@mui/icons-material"

const DAY_IN_SECONDS = 86400

export const TimelineBlock = ({
  defaultValue,
  onChange,
  id
}: TimelineBlockProps) => {
  const [numShow, setNumShow] = useState(1)
  const [values, setValues] = useState(defaultValue)
  const [editingFuturItems, setEditingFuturItems] = useState<Array<number>>([])

  useEffect(() => {
    setValues(defaultValue)
  }, [defaultValue])

  const handleLoadPreviousClick = useCallback(() => {
    setNumShow((prevState) => prevState + 1)
  }, [setNumShow])

  const handleLoadLessClick = useCallback(() => {
    setNumShow(1)
    setEditingFuturItems([])
  }, [setNumShow])

  const isTimestampFuture = (timestamp: number) => {
    const todaysTimestamp = Math.round(+new window.Date() / 1000)
    return timestamp > todaysTimestamp && !isToday(fromUnixTime(timestamp))
  }

  useEffect(() => {
    const todaysTimestamp = Math.round(+new window.Date() / 1000)

    const valuesToday = (values ?? []).filter(
      ({ timestamp }) =>
        differenceInDays(
          fromUnixTime(todaysTimestamp),
          fromUnixTime(timestamp)
        ) === 0
    )

    if (valuesToday.length === 0) {
      const nextValues = [
        ...(values ?? []),
        {
          timestamp: Math.round(+new window.Date() / 1000),
          text: ``
        }
      ]
      setValues(nextValues)
      onChange?.(nextValues)
      return
    }
  }, [onChange, values])

  const formattedItems = useMemo(() => {
    const nextItems = orderBy(values, [`timestamp`], [`asc`]).map(
      ({ timestamp, text }) => ({
        timestamp,
        text,
        date: format(
          fromUnixTime(timestamp),
          differenceInCalendarDays(new window.Date(), fromUnixTime(timestamp)) >
            2 ||
            differenceInCalendarDays(
              new window.Date(),
              fromUnixTime(timestamp)
            ) < -5
            ? `EEE do LLL`
            : `EEE`
        )
      })
    )

    const itemsInFuture = nextItems.filter(({ timestamp }) =>
      isTimestampFuture(timestamp)
    )

    return nextItems.slice((numShow + itemsInFuture.length) * -1)
  }, [values, numShow])

  const handleOnchange = useCallback(
    (timestamp: number) => (value: string) => {
      const nextValues = (values ?? []).map((item) => {
        const nextItem = item
        if (item.timestamp === +timestamp) {
          nextItem.text = value
        }
        return nextItem
      })
      setValues(nextValues)
      onChange?.(nextValues)
    },
    [onChange, values]
  )

  const handleFutureItemClick = useCallback(
    (timestamp: number) => () => {
      if (editingFuturItems.includes(timestamp)) return
      setEditingFuturItems((prevState) => [...prevState, timestamp])
    },
    [editingFuturItems]
  )

  const handleDateChange = useCallback(
    (timestamp: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!e?.target?.value) return

      const value = e?.target?.value
      const newTimestamp = new window.Date(value).getTime() / 1000

      let nextValues = values

      const itemsWithTimestamp = nextValues?.filter((item) => {
        return item?.timestamp === newTimestamp
      })

      if ((itemsWithTimestamp ?? []).length < 1) {
        nextValues = nextValues?.map((item) => {
          const nextItem = item
          if (item?.timestamp === timestamp) {
            item.timestamp = newTimestamp
          }
          return nextItem
        })

        setValues(nextValues)
        onChange?.(nextValues ?? [])
      }
      setEditingFuturItems((prevState) => {
        const newState = [...prevState, newTimestamp]
        return newState.filter((item) => item !== timestamp)
      })
    },
    [onChange, values]
  )

  const handleAddNewClick = useCallback(() => {
    const newValues = orderBy(values, [`timestamp`], [`asc`])
    const lastValue = newValues?.[newValues?.length - 1]
    const todaysTimestamp = Math.round(+new window.Date() / 1000)

    if (!lastValue?.timestamp) return values

    let nextTimestamp = lastValue?.timestamp + DAY_IN_SECONDS

    if (isToday(fromUnixTime(lastValue?.timestamp))) {
      nextTimestamp = todaysTimestamp + DAY_IN_SECONDS
    }

    const nextValues = orderBy(
      [
        ...(values ?? []),
        {
          timestamp: nextTimestamp,
          text: ``
        }
      ],
      [`timestamp`],
      [`asc`]
    )

    setValues(nextValues)
    onChange?.(nextValues)
  }, [onChange, values])

  return (
    <Container>
      <LoadPrevious>
        <IconButton size="small" onClick={handleLoadPreviousClick}>
          <KeyboardArrowUp fontSize="small" />
        </IconButton>
      </LoadPrevious>
      {formattedItems.map(({ date, text, timestamp }) => {
        if (
          isTimestampFuture(timestamp) &&
          !editingFuturItems.includes(timestamp)
        ) {
          return (
            <ItemContainer
              onClick={handleFutureItemClick(timestamp)}
              collapse
              inactive
              key={timestamp}
            >
              <Date>{date}</Date>
            </ItemContainer>
          )
        }
        return (
          <ItemContainer key={timestamp}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Date>
                  {isTimestampFuture(timestamp) &&
                  editingFuturItems.includes(timestamp) ? (
                    <>
                      {format(fromUnixTime(timestamp), `EEE`)}
                      <DatePicker
                        type="date"
                        defaultValue={format(
                          fromUnixTime(timestamp),
                          `yyyy-MM-dd`
                        )}
                        onBlur={handleDateChange(timestamp)}
                        required
                      />
                    </>
                  ) : (
                    date
                  )}
                </Date>
              </Grid>
              <Grid item xs={12}>
                <TextInputContainer>
                  <RichTextInput
                    onChange={handleOnchange(timestamp)}
                    defaultValue={text}
                    id={`${id}${date}`}
                  />
                </TextInputContainer>
              </Grid>
            </Grid>
          </ItemContainer>
        )
      })}
      <AddNew>
        <IconButton
          aria-label="Load More"
          size="small"
          onClick={handleAddNewClick}
        >
          <AddCircleOutline fontSize="small" />
        </IconButton>
      </AddNew>
      {(numShow > 1 || editingFuturItems.length > 0) && (
        <Contract>
          <IconButton
            aria-label="Show Less"
            size="small"
            onClick={handleLoadLessClick}
          >
            <UnfoldLess fontSize="small" />
          </IconButton>
        </Contract>
      )}
    </Container>
  )
}

export default TimelineBlock
