import React, { useState, useCallback } from "react"
import {
  OutlinedInput,
  InputAdornment,
  CircularProgress,
  IconButton
} from "@mui/material"
import { CameraAlt, Mic } from "@mui/icons-material"
import styled from "styled-components"
import superagent from "superagent"
import imageCompression from "browser-image-compression"

const apiKey = `AIzaSyChO0NgTu1bNp9daJvf8kj7XuiVUBinc1Y`

const Loading = styled(CircularProgress)`
  width: 24px !important;
  height: 24px !important;
`

type ValueInputProps = {
  inputProps?: any
  setValue?: any
  onChange?: (value: string) => void
  type?: `number` | `text`
}

const Container = styled.div`
  && {
    .MuiInputBase-root {
      background-color: white;
    }
  }
`

const ValueInput = ({
  inputProps,
  setValue,
  onChange,
  type = `text`
}: ValueInputProps) => {
  const [compressionProgress, setCompressionProgress] = useState(100)
  const [recordingSpeech, setRecordingSpeech] = useState(false)

  const handlePhoto = async (event) => {
    const imageFile = event.target.files[0]

    const options = {
      maxSizeMB: 1,
      useWebWorker: true,
      onProgress: (progress) => {
        setCompressionProgress(progress)
      }
    }

    try {
      const compressedFile = await imageCompression(imageFile, options)
      const base64 = await imageCompression.getDataUrlFromFile(compressedFile)
      const base64WithoutPrefix = base64.replace(/^data:image\/\w+;base64,/, ``)

      superagent
        .post(`https://vision.googleapis.com/v1/images:annotate?key=${apiKey}`)
        .send({
          requests: [
            {
              image: {
                content: base64WithoutPrefix
              },
              features: [
                {
                  type: `TEXT_DETECTION`
                }
              ]
            }
          ]
        })
        .end((err, res) => {
          const match = res.body.responses[0].textAnnotations[0].description

          if (type === `number`) {
            const number = match.replace(/[^0-9.]/g, ``)
            onChange?.(number)
            setValue?.(`value`, number)
            return
          }

          onChange?.(match)
          setValue?.(`value`, match)
        })
    } catch (error) {
      console.log(error)
    }
  }

  const handleMicClick = useCallback(() => {
    const SpeechRecognition =
      window.SpeechRecognition || (window as any).webkitSpeechRecognition
    const recognition = new SpeechRecognition()

    recognition.lang = `en-GB`
    recognition.start()

    setRecordingSpeech(true)

    recognition.onresult = (event) => {
      setRecordingSpeech(false)
      const current = event.resultIndex
      const transcript = event.results[current][0].transcript
      onChange?.(transcript)
      setValue?.(`value`, transcript)
    }
  }, [onChange, setValue])

  return (
    <Container>
      <OutlinedInput
        type={type}
        placeholder="Value"
        fullWidth
        onChange={(event) => {
          onChange?.(event.target.value)
        }}
        {...inputProps}
        endAdornment={
          <InputAdornment position="end">
            {compressionProgress < 100 ? (
              <Loading variant="determinate" value={compressionProgress} />
            ) : (
              <IconButton edge="end" size="large">
                <input
                  type="file"
                  accept="image/*"
                  capture="environment"
                  style={{
                    position: `absolute`,
                    width: `100%`,
                    height: `100%`,
                    opacity: 0,
                    cursor: `pointer`
                  }}
                  onChange={handlePhoto}
                />
                <CameraAlt />
              </IconButton>
            )}

            <IconButton edge="end" size="large">
              {recordingSpeech ? <Loading /> : <Mic onClick={handleMicClick} />}
            </IconButton>
          </InputAdornment>
        }
      />
    </Container>
  )
}

export default ValueInput
