import styled, { css } from "styled-components"
import { ListItemButton as ListItemBase, List as ListBase} from "@mui/material"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  width: ${100 / 6}%;
`

export const Footer = styled.div`
  display: flex;
  padding: 1rem;
  background-color: #fafafa;
`

export const List = styled(ListBase)`
  overflow-y: auto;
`

export const ListItem = styled(ListItemBase)<
  { $active?: boolean; $noHover?: boolean; $bottom?: boolean }
>`
  && {
    color: #444;
    ${({ $active }) =>
      $active &&
      css`
        background-color: #5e7ff5;
        color: #fff;
      `}
    ${({ $noHover }) =>
      !$noHover &&
      css`
        &:hover {
          background-color: #5e7ff5;
          color: #fff;
        }
      `}
    ${({ $bottom }) =>
      $bottom &&
      css`
        position: absolute;
        bottom: 0;
      `}
  }
`
