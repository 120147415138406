import React, { useState, useCallback } from "react"
import IconButton from "@mui/material/IconButton"
import { Settings } from "@mui/icons-material"
import {
  DialogTitle,
  Dialog,
  DialogContent,
  Grid,
  FormLabel,
  Button,
  DialogActions,
  FormHelperText
} from "@mui/material"
import { useAuth } from "../../hooks/useAuth"

export const SettingsDialogue = () => {
  const [open, setOpen] = useState(false)
  const { signIn, signOut, user } = useAuth()

  const handleIconClick = useCallback(() => {
    setOpen((prevState) => !prevState)
  }, [])

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])

  return (
    <>
      <IconButton onClick={handleIconClick} size="large">
        <Settings />
      </IconButton>
      <Dialog onClose={handleClose} open={open} fullWidth maxWidth="sm">
        <DialogTitle>Settings</DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <FormLabel>Connect to Google</FormLabel>
              <FormHelperText>
                Share notes across multiple devices by connecting your Google
                account. Notes on this device will be discarded.
              </FormHelperText>
            </Grid>
            <Grid item xs={12}>
              {!user && (
                <Button onClick={signIn} variant="contained" color="primary">
                  Sign In
                </Button>
              )}
              {user && (
                <Button onClick={signOut} variant="contained" color="primary">
                  Sign Out
                </Button>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default SettingsDialogue
