import styled, { css } from "styled-components"
import { up } from "styled-breakpoints"

export const MenuContainer = styled.div<{
  open: boolean
  top: number
  left: number
}>`
  position: absolute;
  ${({ top, left }) =>
    css`
      top: ${top}px;
      left: ${left}px;
    `}
  overflow-y: auto;
  z-index: 9;
  display: none;
  background-color: #fff;
  border-radius: 0.5rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  ul {
    margin: 0;
    padding: 0;
  }
  ${({ open }) =>
    open &&
    css`
      display: block;
    `}
  *[data-trix-active] {
    background-color: #ccc;
  }
  width: 100%;
  max-width: calc(100% - 40px);
  ${up("md")} {
    width: auto;
    max-width: none;
  }
`

export const ToolbarContainer = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
`

export const ToolbarItem = styled.button`
  display: inline-block;
  cursor: pointer;
  background: transparent;
  border: 0;
  &[disabled] {
    display: none;
  }
  &:hover {
    background-color: #f5f5f5;
  }
`

export const ToolbarIconContainer = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`
