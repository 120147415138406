import React, { useCallback, useRef, useEffect, useState } from "react"
import { Grid, Button } from "@mui/material"
import styled from "styled-components"
import { NoteSettingsProps } from "./types"
import { IconButton } from "@mui/material"
import { Delete } from "@mui/icons-material"
import { Check, Widgets, Close } from "@mui/icons-material"
import { up } from "styled-breakpoints"
import useMediaQuery from "../../hooks/useMediaQuery"

const TagContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #5e7ff5;
  border-radius: 5px;
  padding: 5px 10px;
  margin: 0 5px;
  box-sizing: border-box;
  position: relative;
`

const TagText = styled.div`
  font-size: 0.8rem;
  font-weight: 600;
  color: #fff;
  white-space: nowrap;
`

const TagArrow = styled.div<{ left?: boolean }>`
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #5e7ff5;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  ${({ left }) =>
    left &&
    `
    border-left: none;
    border-right: none;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-right: 5px solid #5e7ff5;
    left: -5px;
    top: 50%;
    transform: translateY(-50%);
  `}
`

const Tag = ({ text, arrowLeft }: { text: string; arrowLeft?: boolean }) => (
  <TagContainer>
    <TagText>{text}</TagText>
    <TagArrow left={arrowLeft} />
  </TagContainer>
)

const StyledButton = styled(Button)`
  background-color: #fff;
  color: #5e7ff5;
  font-weight: 600;
  &:hover {
    background-color: #fff;
    color: #5e7ff5;
  }
`

export const Settings = styled.div<{ configuring: boolean }>`
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  ${({ configuring }) =>
    configuring &&
    `
    background-color: #fff;
  `}
}
`

export const MaxWidth = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`

export const BlockOption = styled.button<{
  onClick: (name: string) => void
  comingSoon?: boolean
}>`
  background-color: #5e7ff5;
  border: none;
  color: #fff;
  display: flex;
  padding: 20px;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
  ${up(`md`)} {
    height: 200px;
    width: 200px;
    padding: 15px;
  }
  &:hover {
    transform: scale(1.02);
  }
  ${({ comingSoon }) =>
    comingSoon &&
    `
    background-color: #f5f5f5;
    color: #000;
    cursor: not-allowed;
  `}
`

export const BlockTitle = styled.h3`
  font-size: 1.2rem;
  font-weight: 500;
  margin: 0 0 5px 0;
  font-weight: 600;
`

export const BlockDescription = styled.div`
  margin-bottom: 10px;
`

export const IconContainer = styled.div`
  position: absolute;
  top: -70px;
  right: -70px;
  z-index: 0;
  svg {
    width: 230px;
    height: 230px;
    fill: rgba(255, 255, 255, 0.1);
  }
`

export const BlockContent = styled.div`
  position: relative;
  z-index: 1;
`

const AddBlockIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
`

const Footer = styled.div<{
  hasEditorComponents: boolean
  configuring: boolean
}>`
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
  color: #5e7ff5;
  display: inline-block;
  ${({ configuring }) => configuring && `background-color: #fafafa;`}
  svg {
    display: block;
  }
  svg * {
    fill: #ccc;
  }
`

const Container = styled.div`
  margin: 0 15px;
`

const IconLabel = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  font-weight: 600;
  cursor: pointer;
  padding-right: 20px;
`

const TagAbsoluteContainer = styled.div`
  position: absolute;
  top: -24px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
`

export const NoteSettings = ({
  blocks,
  onBlockOptionClick,
  onDeleteClick,
  noteDeleteConfirmed,
  onSettingsClose,
  setConfiguring,
  configuring,
  editorComponentCount
}: NoteSettingsProps) => {
  const settingsRef = useRef<HTMLDivElement>(null)
  const timerRef = useRef<NodeJS.Timeout | null>(null)
  const [ showBlockTags, setShowBlockTags ] = useState(false)

  const hasEditorComponents = editorComponentCount > 0
  const isMobile = useMediaQuery(`(max-width: 768px)`)

  const handleAddBlockClick = useCallback(() => {
    setConfiguring((prev) => !prev)
    onSettingsClose()
  }, [onSettingsClose, setConfiguring])

  useEffect(() => {
    clearTimeout(timerRef.current || 0)
    timerRef.current = setTimeout(() => {
      setShowBlockTags(editorComponentCount === 0)
    }, 3000)
  }, [editorComponentCount])

  const handleBlockClick = useCallback(
    (name: string) => {
      onBlockOptionClick(name)

      const page = document.getElementById(`page`)
      if (page) {
        page.scrollTo({
          top: settingsRef.current?.offsetTop || 0,
          behavior: `smooth`
        })
      }
    },
    [onBlockOptionClick]
  )

  useEffect(() => {
    if (configuring) {
      const page = document.getElementById(`page`)
      if (page) {
        setTimeout(() => {
          page.scrollTo({
            top: settingsRef.current?.offsetTop || 0,
            behavior: `smooth`
          })
        }, 10)
      }
    }
  }, [configuring])

  return (
    <Container>
      <Footer
        hasEditorComponents={hasEditorComponents}
        configuring={configuring}
      >
        <Grid
          container
          height="100%"
          alignItems="center"
          justifyContent={isMobile ? 'center' : 'right'}>
          <Grid item>
            <AddBlockIconContainer>
              {showBlockTags && !configuring && (
                <TagAbsoluteContainer>
                  <Tag text="Add Blocks Here" />
                </TagAbsoluteContainer>
              )}
              <IconButton size="large" onClick={handleAddBlockClick}>
                {configuring ? <Close /> : <Widgets />}
              </IconButton>
            </AddBlockIconContainer>
          </Grid>
          
        </Grid>
      </Footer>
      <Settings configuring={configuring} ref={settingsRef}>
        <Grid container spacing={2}>
          {configuring && (
            <>
              {Object.keys(blocks)
                ?.map((key) => blocks[key])
                ?.map(
                  ({ title, icon: Icon, name, description, comingSoon }) => (
                    <Grid item>
                      <BlockOption
                        onClick={() => handleBlockClick(name)}
                        comingSoon={comingSoon}
                      >
                        {Icon && (
                          <IconContainer>
                            <Icon fontSize="large" />
                          </IconContainer>
                        )}
                        <BlockContent>
                          <Grid container spacing={1}>
                            <Grid item xs={12}>
                              <BlockTitle>{title}</BlockTitle>
                            </Grid>
                            <Grid item xs={12}>
                              <BlockDescription>{description}</BlockDescription>
                            </Grid>
                            {!comingSoon && (
                              <Grid item xs={12}>
                                <StyledButton size="small">Add</StyledButton>
                              </Grid>
                            )}
                          </Grid>
                        </BlockContent>
                      </BlockOption>
                    </Grid>
                  )
                )}
              <Grid item xs={12}>
                <IconLabel onClick={onDeleteClick}>
                  <IconButton size="large">
                    {!noteDeleteConfirmed ? <Delete /> : <Check />}
                  </IconButton>
                  <div>{!noteDeleteConfirmed ? `Delete Note` : `Confirm`}</div>
                </IconLabel>
              </Grid>
            </>
          )}
        </Grid>
      </Settings>
    </Container>
  )
}

export default NoteSettings
