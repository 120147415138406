import styled from "styled-components"

export const Tag = styled.div`
  position: fixed;
  bottom: -29px;
  right: -6px;
  transform: rotate(45deg);
  background-color: #5e7ff5;
  width: 40px;
  height: 86px;
  cursor: pointer;
  z-index: 100;
`
