import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react"
import Editor from "../../components/Editor"
import { useParams } from "react-router-dom"
import { EditorComponents } from "../../components/Editor"
import { NotesContext } from "../../contexts"
import { Page } from "./NotePage.styled"
import { useHistory } from "react-router-dom"
import { useIdleTimer } from "react-idle-timer"
import { debounce } from "lodash"
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

export const NotePage = () => {
  const [configuring, setConfiguring] = useState(false)
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarText, setSnackbarText] = React.useState('');
  const params = useParams<{ id: string }>()
  const history = useHistory()

  const handleSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
  };

  const id = params?.id

  const { notes, setNotes, syncNotes, error } = useContext(NotesContext) ?? {
    notes: {},
    setNotes: () => {},
    syncNotes: () => {},
    error: null
  }

  const debouncedSetNotes = debounce(setNotes, 1000)

  const handleIdleTimerActive = useCallback(
    (e) => {
      if (e?.type !== `keydown`) {
        syncNotes?.()
      }
    },
    [syncNotes]
  )

  useIdleTimer({
    timeout: 5000,
    onActive: handleIdleTimerActive,
    debounce: 500
  })

  const note = useMemo(() => notes?.[id ?? -1] ?? null, [id, notes])

  useEffect(() => {
    if (!id && Object.keys(notes ?? {})?.[0]) {
      const allNotes = Object.keys(notes ?? {})
      const sortedNotesAlphabetically = allNotes.sort()
      const firstNote = sortedNotesAlphabetically?.[0]
      history.push(`/${firstNote}`)
    }
  }, [notes, history, id])

  useEffect(() => {
    if (error) {
      setSnackbarOpen(true)
      setSnackbarText(error)
    } else {
      setSnackbarOpen(false)
      setSnackbarText('')
    }
  }, [error, history])

  const handleNoteUpdate = useCallback(
    (data: EditorComponents) => {
      if (!id || !notes) return
      const nextNotes = notes
      nextNotes[id] = { content: data }
      debouncedSetNotes(nextNotes)
    },
    [id, notes, debouncedSetNotes]
  )

  return (
    <Page configuring={configuring} id="page">
      <Editor
        note={note}
        handleNoteUpdate={handleNoteUpdate}
        configuring={configuring}
        setConfiguring={setConfiguring}
        key={id}
        id={id}
      />
      <Stack spacing={2} sx={{ width: '100%' }}>
        <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={handleSnackbarClose} action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleSnackbarClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }>
          <Alert onClose={handleSnackbarClose} severity="error" sx={{ width: '100%' }}>
            {snackbarText}
          </Alert>
        </Snackbar>
      </Stack>
    </Page>
  )
}

export default NotePage
