import React, { useState, useCallback, useEffect, useContext } from "react"

import shortid from "shortid"
import { omit } from "lodash"
import { DropResult } from "react-beautiful-dnd"
import arrayMove from "array-move"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import {
  EditorComponentsContainer,
  View,
  ComponentToolbar,
  DragIconButton,
  DeleteIconButton,
  ConfirmDeleteIconButton,
  EditorComponentContainer
} from "./Editor.styled"
import { Grid } from "@mui/material"
import { Close, DragIndicator, Check } from "@mui/icons-material"
import { EditorComponents, EditorProps } from "./types"
import { NotesContext } from "../../contexts"
import { useHistory } from "react-router-dom"
import NoteSettings from "../NoteSettings"
import { blocks } from "../../blocks"

export const Editor = ({
  note,
  handleNoteUpdate,
  configuring,
  setConfiguring,
  id: noteId
}: EditorProps) => {
  const [editorComponents, setEditorComponents] = useState<EditorComponents>([])
  const [editorDeleteConfirmed, setEditorDeleteConfirmed] = useState<
    Array<string>
  >([])
  const [noteDeleteConfirmed, setNoteDeleteConfirmed] = useState<boolean>(false)

  const { notes, setNotes } = useContext(NotesContext) ?? { notes: {} }
  const history = useHistory()

  useEffect(() => {
    setEditorComponents(note?.content ?? [])
  }, [JSON.stringify(note)])

  const handleDragEnd = useCallback(
    ({ source, destination }: DropResult) => {
      if (!destination) return
      if (
        destination.droppableId === source.droppableId &&
        destination.index === source.index
      ) {
        return
      }
      const nextEditorComponents = arrayMove(
        editorComponents,
        source.index,
        destination.index
      ).sort()
      handleNoteUpdate(nextEditorComponents)
      setEditorComponents(nextEditorComponents)
    },
    [editorComponents, handleNoteUpdate]
  )

  const handleBlockOptionClick = useCallback(
    (blockName: string) => {
      const id = shortid.generate()
      const nextEditorComponents = [...editorComponents, { id, blockName }]
      setEditorComponents(nextEditorComponents)
      handleNoteUpdate(nextEditorComponents)
    },
    [editorComponents, handleNoteUpdate]
  )

  const handleComponentChange = useCallback(
    (id: string) => (value: string) => {
      const nextEditorComponents = editorComponents.map((component) => ({
        ...component,
        ...(id === component.id ? { value } : {})
      }))
      setEditorComponents([...nextEditorComponents])
      handleNoteUpdate(nextEditorComponents)
    },
    [editorComponents, handleNoteUpdate]
  )

  const handleDeleteIconClick = useCallback(
    (deletingId: string) => () => {
      if (editorDeleteConfirmed?.includes(deletingId)) {
        const nextEditorComponents = editorComponents.filter(
          ({ id }) => id !== deletingId
        )
        setEditorComponents(nextEditorComponents)
        handleNoteUpdate(nextEditorComponents)

        setEditorDeleteConfirmed((prevState) =>
          prevState.filter((id) => id !== deletingId)
        )
      } else {
        setEditorDeleteConfirmed((prevState) => [...prevState, deletingId])
      }
    },
    [editorDeleteConfirmed, editorComponents, handleNoteUpdate]
  )

  const handleDeleteClick = useCallback(() => {
    if (noteDeleteConfirmed) {
      setNotes?.(omit(notes, [noteId]))
      const firstNote = Object.keys(notes ?? {})?.[0]
      history.push(`/${firstNote}`)
    } else {
      setNoteDeleteConfirmed(true)
    }
  }, [noteDeleteConfirmed, notes, setNotes, noteId, history])

  return (
    <>
      <View>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <EditorComponentsContainer>
              <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="editor">
                  {(provided, snapshot) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      <Grid container spacing={1}>
                        {editorComponents.map(
                          ({ blockName, value, id }, index) => {
                            const EditorComponent = blocks[blockName]?.component
                            if (!EditorComponent) return null
                            return (
                              <Draggable
                                key={id}
                                draggableId={id}
                                index={index}
                                isDragDisabled={!configuring}
                              >
                                {(provided) => (
                                  <Grid
                                    item
                                    xs={12}
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                  >
                                    {configuring && (
                                      <ComponentToolbar>
                                        {editorDeleteConfirmed?.includes(
                                          id
                                        ) && (
                                          <ConfirmDeleteIconButton
                                            aria-label="Confirm"
                                            size="small"
                                            onClick={handleDeleteIconClick(id)}
                                          >
                                            <Check fontSize="small" />
                                          </ConfirmDeleteIconButton>
                                        )}
                                        {!editorDeleteConfirmed?.includes(
                                          id
                                        ) && (
                                          <DeleteIconButton
                                            aria-label="Delete"
                                            size="small"
                                            onClick={handleDeleteIconClick(id)}
                                          >
                                            <Close fontSize="small" />
                                          </DeleteIconButton>
                                        )}
                                        <DragIconButton
                                          {...provided.dragHandleProps}
                                          aria-label="Delete"
                                          size="small"
                                        >
                                          <DragIndicator fontSize="small" />
                                        </DragIconButton>
                                      </ComponentToolbar>
                                    )}
                                    <EditorComponentContainer
                                      configuring={configuring}
                                    >
                                      <EditorComponent
                                        id={id}
                                        defaultValue={value}
                                        onChange={handleComponentChange(id)}
                                      />
                                    </EditorComponentContainer>
                                  </Grid>
                                )}
                              </Draggable>
                            )
                          }
                        )}
                      </Grid>
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </EditorComponentsContainer>
          </Grid>
        </Grid>
      </View>
      <NoteSettings
        onBlockOptionClick={handleBlockOptionClick}
        onDeleteClick={handleDeleteClick}
        onSettingsClose={() => {
          setEditorDeleteConfirmed([])
          setNoteDeleteConfirmed(false)
        }}
        noteDeleteConfirmed={noteDeleteConfirmed}
        blocks={blocks}
        setConfiguring={setConfiguring}
        configuring={configuring}
        editorComponentCount={editorComponents?.length ?? 0}
      />
    </>
  )
}

export default Editor
