import React, { useState, useCallback, useEffect } from "react"
import IconButton from "@mui/material/IconButton"
import {
  AddCircleOutline,
  RemoveCircleOutline,
  Cached,
  ZoomOutMap,
  DeleteSweep,
  Check,
  DataObject
} from "@mui/icons-material"
import Ajv from "ajv"
import addFormats from "ajv-formats"
import {
  DialogTitle,
  Dialog,
  DialogContent,
  Grid,
  Button,
  DialogActions,
  OutlinedInput,
  FormHelperText,
  TextareaAutosize
} from "@mui/material"
import { format } from "date-fns"
import { useForm } from "react-hook-form"
import { MenuProps } from "./types"
import ValueInput from "../../../ValueInput"
import CreatableSelect from "react-select/creatable"
import {
  ColourSvgContainer,
  ConfirmDeleteIconButton,
  DatasetIconContainer,
  DatasetLabel,
  IconButtonLabel,
  MenuGridContainer,
  DatasetIconGrid,
  TextAlign,
  ButtonGrid
} from "./Menu.styled"

const Menu = ({
  onSubmit,
  onRemovePoint,
  onRemoveDataset,
  datasets = [],
  data = [],
  showRemoveButton,
  onResetButtonClick,
  showResetButton,
  onZoomFitClick,
  onDatasetClick,
  onDataEditFormSubmit
}: MenuProps) => {
  const datasetLabels = datasets.map((dataset) => dataset.label)

  const [open, setOpen] = useState(false)
  const [visibleDatasets, setVisibleDatasets] =
    useState<string[]>(datasetLabels)
  const [confirmDeletePoint, setConfirmDeletePoint] = useState(false)
  const [confirmDeleteDataset, setConfirmDeleteDataset] = useState(false)
  const [dataEditOpen, setDataEditOpen] = useState(false)
  const [confirmEditData, setConfirmEditData] = useState(false)
  const [dataEditValue, setDataEditValue] = useState(``)
  const [dataEditValueInvalid, setDataEditValueInvalid] = useState(false)

  const datasetDeletionEnabled = false

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    watch
  } = useForm({
    defaultValues: {
      dataset: datasetLabels[0],
      value: ``,
      date: format(new Date(), `yyyy-MM-dd'T'HH:mm`).toString(),
      comments: ``
    }
  })

  const [datasetOptions, setDatasetOptions] = useState(
    datasetLabels.map((dataset) => ({
      value: dataset,
      label: dataset
    }))
  )

  register(`dataset`, { required: true })

  const datasetValue = watch(`dataset`)

  const onFormSubmit = (data) => {
    const date = format(new Date(data.date), `yyyy-MM-dd HH:mm:ss`)
    onSubmit({
      x: date,
      y: data.value.toString(),
      comments: data.comments,
      dataset: data.dataset
    })
    handleClose()
  }

  const handleAddButtonClick = useCallback(() => {
    setOpen((prevState) => !prevState)
  }, [])

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])

  useEffect(() => {
    if (!open) {
      reset()
    }
    if (open) {
      setValue(`date`, format(new Date(), `yyyy-MM-dd'T'HH:mm`).toString())
    }
  }, [open, reset, setValue])

  const handleDataIconClick = useCallback(
    ({ label }) => {
      if (visibleDatasets.length === datasetLabels.length) {
        setVisibleDatasets([label])
        onDatasetClick({ datasetsToShow: [label] })
        return
      }

      if (visibleDatasets.length === 1 && visibleDatasets.includes(label)) {
        setVisibleDatasets(datasetLabels)
        onDatasetClick({ datasetsToShow: datasetLabels })
        return
      }

      if (
        visibleDatasets.length < datasetLabels.length &&
        !visibleDatasets.includes(label)
      ) {
        setVisibleDatasets([...visibleDatasets, label])
        onDatasetClick({ datasetsToShow: [...visibleDatasets, label] })
        return
      }

      if (visibleDatasets.length > 1 && visibleDatasets.includes(label)) {
        const datasetsFiltered = visibleDatasets.filter(
          (dataset) => dataset !== label
        )
        setVisibleDatasets(datasetsFiltered)
        onDatasetClick({ datasetsToShow: datasetsFiltered })
        return
      }
    },
    [datasetLabels, onDatasetClick, visibleDatasets]
  )

  const handleDataButtonClick = useCallback(
    () => setDataEditOpen((prevState) => !prevState),
    []
  )

  const handleDataButtonClose = useCallback(() => {
    setDataEditOpen(false)
  }, [])

  const handleDataChange = useCallback(
    (e) => {
      const { value } = e.target

      if (value === ``) return

      const schema = {
        type: `array`,
        items: {
          type: `object`,
          properties: {
            data: {
              type: `array`,
              items: {
                type: `object`,
                required: [`x`, `y`],
                properties: {
                  x: {
                    type: `string`,
                    format: `date-time`,
                    title: `x`
                  },
                  y: {
                    type: [`string`, `number`]
                  },
                  comments: {
                    type: `string`,
                    nullable: true
                  }
                }
              }
            },
            label: {
              type: `string`
            }
          }
        }
      }

      const ajv = new Ajv({allowUnionTypes: true, allowDate: true})
      addFormats(ajv, [`date-time`])

      const validate = ajv.compile(schema)
      const valueParsed = JSON.parse(value)
      const isValid = validate(valueParsed)

      setConfirmEditData(false)

      if (isValid) {
        setDataEditValue(value)
        setDataEditValueInvalid(false)
      } else {
        setDataEditValueInvalid(true)
      }
    },
    [setDataEditValue]
  )

  return (
    <>
      <MenuGridContainer
        container
        datasetCount={datasetLabels.length}
        justifyContent="space-between"
      >
        <DatasetIconGrid item>
          <Grid container spacing={1}>
            {datasets.map(({ label, colour }) => (
              <Grid item>
                <IconButtonLabel>
                  <IconButton
                    size="small"
                    onClick={() => handleDataIconClick({ label })}
                  >
                    <ColourSvgContainer key={label}>
                      <svg
                        version="1.1"
                        viewBox="0 0 500 500"
                        preserveAspectRatio="xMinYMin meet"
                      >
                        <title>{label}</title>
                        <circle
                          cx="250"
                          cy="250"
                          r="200"
                          fill={colour}
                          opacity={visibleDatasets.includes(label) ? 1 : 0.5}
                        />
                      </svg>
                    </ColourSvgContainer>
                  </IconButton>
                  <DatasetLabel>{label}</DatasetLabel>
                </IconButtonLabel>
              </Grid>
            ))}
          </Grid>
        </DatasetIconGrid>
        <ButtonGrid item>
          <TextAlign align="right">
            <IconButton size="small" onClick={onZoomFitClick} title="View All">
              <ZoomOutMap fontSize="small" />
            </IconButton>
            {showResetButton && (
              <IconButton
                size="small"
                onClick={onResetButtonClick}
                title="Reset View"
              >
                <Cached fontSize="small" />
              </IconButton>
            )}
            {showRemoveButton && (
              <>
                {confirmDeletePoint && (
                  <ConfirmDeleteIconButton
                    size="small"
                    onClick={() => {
                      onRemovePoint()
                      setConfirmDeletePoint(false)
                    }}
                    title="Delete Point"
                  >
                    <Check fontSize="small" />
                  </ConfirmDeleteIconButton>
                )}
                {!confirmDeletePoint && (
                  <IconButton
                    size="small"
                    onClick={() => setConfirmDeletePoint(true)}
                    title="Delete data point"
                  >
                    <RemoveCircleOutline fontSize="small" />
                  </IconButton>
                )}

                {datasetDeletionEnabled && confirmDeleteDataset && (
                  <ConfirmDeleteIconButton
                    size="small"
                    onClick={() => {
                      onRemoveDataset()
                      setConfirmDeleteDataset(false)
                    }}
                    title="Delete Dataset"
                  >
                    <Check fontSize="small" />
                  </ConfirmDeleteIconButton>
                )}
                {datasetDeletionEnabled && !confirmDeleteDataset && (
                  <IconButton
                    size="small"
                    onClick={() => setConfirmDeleteDataset(true)}
                    title="Delete dataset"
                  >
                    <DeleteSweep fontSize="small" />
                  </IconButton>
                )}
              </>
            )}
            <IconButton
              size="small"
              onClick={handleDataButtonClick}
              title="Edit JSON Data Object"
            >
              <DataObject fontSize="small" />
            </IconButton>
            <IconButton
              size="small"
              onClick={handleAddButtonClick}
              title="New Entry"
            >
              <AddCircleOutline fontSize="small" />
            </IconButton>
          </TextAlign>
        </ButtonGrid>
      </MenuGridContainer>
      <Dialog open={dataEditOpen} onClose={handleDataButtonClose} fullWidth maxWidth="xs">
        <DialogTitle>Edit Data</DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextareaAutosize
                    minRows={20}
                    maxRows={30}
                    placeholder="JSON Data Object"
                    style={{ width: `100%` }}
                    defaultValue={JSON.stringify(data, null, 2)}
                    onChange={handleDataChange}
                  />
                  {dataEditValueInvalid && (
                    <FormHelperText error>
                      Invalid JSON Data Object
                    </FormHelperText>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDataButtonClose}>Close</Button>
          <Button
            color="primary"
            onClick={() => {
              if (confirmEditData) {
                if (dataEditValue) {
                  onDataEditFormSubmit(JSON.parse(dataEditValue))
                  setConfirmEditData(false)
                }
              } else {
                if (!dataEditValueInvalid) {
                  setConfirmEditData(true)
                }
              }
            }
          }>
            {confirmEditData ? `Confirm` : `Submit`}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog onClose={handleClose} open={open} fullWidth maxWidth="xs">
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <DialogTitle>New Entry</DialogTitle>
          <DialogContent>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <CreatableSelect
                      styles={{
                        valueContainer: (provided) => ({
                          ...provided,
                          padding: `14.5px 14px`
                        })
                      }}
                      value={{
                        value: datasetValue,
                        label: datasetValue
                      }}
                      options={datasetOptions}
                      onChange={(option) =>
                        setValue(`dataset`, option?.value ?? ``)
                      }
                      onCreateOption={(option) => {
                        setDatasetOptions([
                          ...datasetOptions,
                          { value: option, label: option }
                        ])
                        setValue(`dataset`, option)
                      }}
                    />
                    {!!errors.dataset && (
                      <FormHelperText error>
                        {errors.dataset.message !== ``
                          ? errors.dataset.message
                          : `Dataset is invalid`}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <ValueInput
                      type="number"
                      setValue={setValue}
                      inputProps={{
                        error: !!errors.value,
                        ...register(`value`, {
                          required: true
                        })
                      }}
                    />
                    {!!errors.value && (
                      <FormHelperText error>
                        {errors.value.message !== ``
                          ? errors.value.message
                          : `Value is invalid`}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <OutlinedInput
                      type="datetime-local"
                      placeholder="Date"
                      fullWidth
                      {...register(`date`, {
                        required: true
                      })}
                      error={!!errors.date}
                    />
                    {!!errors.date && (
                      <FormHelperText error>
                        {errors.date.message !== ``
                          ? errors.date.message
                          : `Date is invalid`}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <OutlinedInput
                      type="text"
                      placeholder="Comments"
                      fullWidth
                      {...register(`comments`, {
                        required: false
                      })}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Close</Button>
            <Button color="primary" onClick={handleSubmit(onFormSubmit)}>
              Submit
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  )
}

export default Menu
