import styled from "styled-components"
import { up, down } from "styled-breakpoints"
import PeabayLogoBase from "./PeabayLogo"

export const Header = styled.div`
  max-width: 1200px;
  margin: 0 auto 40px auto;
  padding: 0 20px;
  ${up(`sm`)} {
    margin: 0 auto 121px auto;
  }
  ${up(`md`)} {
    padding: 0 40px;
  }
`
export const SlantedRectangle = styled.div`
  background: #556cd6;
  clip-path: polygon(0 0, 100% 0, 100% 1%, 0 30%);
  ${up(`sm`)} {
    clip-path: polygon(0 0, 100% 0, 100% 1%, 0 70%);
  }
  ${up(`xl`)} {
    clip-path: polygon(0 0, 100% 0, 100% 1%, 0 92%);
  }
  ${up(`xxl`)} {
    clip-path: polygon(0 0, 100% 0, 100% 1%, 0 100%);
  }
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 280px;
`
export const MainTitle = styled.h1`
  font-size: 3rem;
  margin-top: 6rem;
  margin-bottom: 2rem;
  letter-spacing: 0.01em;
  line-height: 1em;
  ${up(`sm`)} {
    margin-top: 12rem;
  }
  ${up(`xxl`)} {
    font-size: 5rem;
  }
`
export const MainSubitle = styled.p`
  font-size: 1.25rem;
  line-height: 1.3em;
`
const blockBaseStyles = `
  color: rgb(56, 56, 56);
  font-size: 14px;
  line-height: 1.57143em;
`
export const Button = styled.button`
  background: #556cd6;
  color: #ffffff;
  border: none;
  border-radius: 100px;
  padding: 8px 15px;
  font-size: 1rem;
  cursor: pointer;
`
export const DeviceContainer = styled.div`
  position: relative;
  margin-left: 20px;
  ${up(`md`)} {
    margin-left: 40px;
  }
`
export const Smartphone = styled.div`
  width: 264px;
  height: 533px;
  border-radius: 30px;
  box-shadow: 0 0 20px 0 rgb(0 0 0 / 10%), inset 0 0 10px rgb(0 0 0 / 10%);
  background: #ffffff;
  margin-top: 160px;
  box-sizing: border-box;
  padding: 20px;
  ${blockBaseStyles}
`
export const ScaleBlock = styled.div`
  width: 250px;
  height: 505px;
  transform: scale(0.9);
  transform-origin: top left;
`
export const Desktop = styled.div`
  width: 929px;
  height: 537px;
  border-radius: 20px;
  box-shadow: 10px 10px 20px 0 rgb(0 0 0 / 10%);
  position: absolute;
  top: -104px;
  left: 50px;
  z-index: -1;
  padding: 20px;
  background: #ffffff;
  ${blockBaseStyles}
`
export const DeviceInnerContainer = styled.div`
  overflow-y: scroll;
  height: 100%;
  padding: 2px;
`
export const SecondSection = styled.div`
  background: #f6f9fc;
  padding: 40px 0 220px 0;
  ${up(`md`)} {
    padding: 128px 0 160px 0;
  }
`
export const MaxWidthContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;

  ${up(`md`)} {
    padding: 0 40px;
  }
`
export const Title = styled.h2`
  max-width: 480px;
  letter-spacing: 0.01em;
  ${down(`sm`)} {
    font-size: 2rem;
    margin-top: 0;
  }
  ${up(`sm`)} {
    font-size: 2.6rem;
    margin-top: initial;
  }
`
export const Subtitle = styled.h3<{ noTopMargin?: boolean }>`
  font-size: 1.25rem;
  ${({ noTopMargin }) => noTopMargin && `margin-top: 0;`}
`
export const Paragraph = styled.p`
  font-size: 1.25rem;
  line-height: 1.3em;
`
export const WidgetsGraphic = styled.div`
  display: block;
  ${up(`lg`)} {
    display: grid;
    gap: 25px 25px;
    grid-template-columns: 380px 589px 301px;
    grid-template-rows: 101px 243px 129px 422px;
  }
  ${up(`lg`)} {
    margin-top: -90px;
  }
`
const widgetBaseStyles = `
  background: #ffffff;
  border-radius: 20px;
  box-shadow: 10px 10px 20px 0 rgb(0 0 0 / 10%), inset 0 0 3px rgb(0 0 0 / 10%);
  padding: 30px;
  ${blockBaseStyles}
`
export const WidgetInnerContainer = styled.div`
  overflow-y: scroll;
  height: 100%;
  padding: 2px;
`
export const WidgetOne = styled.div`
  grid-area: 2/1;
  ${widgetBaseStyles}
  margin-bottom: 20px;
  ${up(`lg`)} {
    margin-bottom: 0;
  }
`
export const WidgetTwo = styled.div`
  grid-area: 2/2 / span 2;
  ${widgetBaseStyles}
  margin-bottom: 20px;
  ${up(`lg`)} {
    margin-bottom: 0;
  }
`
export const WidgetThree = styled.div`
  grid-area: span 3/3;
  ${widgetBaseStyles}
  margin-bottom: 20px;
  ${up(`lg`)} {
    margin-bottom: 0;
  }
`
export const WidgetFour = styled.div`
  grid-area: 3/1;
  ${widgetBaseStyles}
  margin-bottom: 20px;
  ${up(`lg`)} {
    margin-bottom: 0;
  }
`
export const WidgetFive = styled.div`
  grid-area: 4/1/4;
  ${widgetBaseStyles}
  margin-bottom: 20px;
  ${up(`lg`)} {
    margin-bottom: 0;
  }
`
export const WidgetSix = styled.div`
  grid-area: 4/2/6/4;
  ${widgetBaseStyles}
  margin-bottom: 20px;
  overflow: hidden;
  ${up(`lg`)} {
    margin-bottom: 0;
    width: calc(100% - 180px);
  }
`

export const ThirdSection = styled.div`
  background: #556cd6;
  clip-path: polygon(0 200px, 100% 0, 100% 100%, 0 100%);
  color: #ffffff;
  padding: 240px 0 350px 0;
  margin-top: -330px;

  ${up(`xl`)} {
    padding: 240px 0 128px 0;
    margin-top: -250px;
  }

  ${up(`xxl`)} {
    padding: 240px 0 128px 0;
    margin-top: -250px;
  }
`
export const TextEditorGraphic = styled.div`
  width: 100%;
  height: 700px;
  border-radius: 20px;
  box-shadow: 10px 10px 20px 0 rgb(0 0 0 / 10%), inset 0 0 5px rgb(0 0 0 / 30%);
  background: #ffffff;
  padding: 30px;
  box-sizing: border-box;
  ${blockBaseStyles}
`
export const FourthSection = styled.div`
  background: #fff;
  clip-path: polygon(0 200px, 100% 0, 100% 100%, 0 100%);
  padding: 240px 0 128px 0;
  margin-top: -330px;

  ${up(`xl`)} {
    padding: 240px 0 128px 0;
  }

  ${up(`xxl`)} {
    padding: 240px 0 128px 0;
  }
`
export const MailchimpForm = styled.form`
  input[type="email"] {
    border: 1px solid #b3b3b3;
    border-radius: 3px;
    padding: 10px 15px;
    width: 250px;
    margin-bottom: 10px;
  }
`
export const Footer = styled.footer`
  background: #f6f9fc;
  padding: 40px 0 40px 0;
  text-align: center;
`
export const PeabayLogo = styled(PeabayLogoBase)`
  width: 100px;
  fill: #999;
`
