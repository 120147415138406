import styled from "styled-components"
import { Lock, LockOpen } from "@mui/icons-material"

export const LockIcon = styled(Lock)``
export const LockOpenIcon = styled(LockOpen)``

export const VisibilityGate = styled.div`
  cursor: pointer;
  svg * {
    fill: rgba(0, 0, 0, 0.11);
  }
  ${LockIcon} {
    display: block;
  }
  ${LockOpenIcon} {
    display: none;
  }
  &:hover {
    ${LockIcon} {
      display: none;
    }
    ${LockOpenIcon} {
      display: block;
    }
  }
`
