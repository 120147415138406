import styled from "styled-components"
import IconButton from "@mui/material/IconButton"
import { Line } from "react-chartjs-2"

export const ConfirmDeleteIconButton = styled(IconButton)`
  && {
    cursor: pointer;
    background-color: #96e283;
    &:hover {
      background-color: #7dce69;
    }
    path {
      fill: #fff;
    }
  }
`

export const LineChart = styled(Line)<{ border?: boolean }>`
  ${({ border }) => border && `
    border-radius: 5px;
    box-shadow: 0 0 10px rgb(0 0 0 / 5%);
  `}
  padding: 15px;
  touch-action: pan-y !important;
`

export const Container = styled.div`
  max-width: 1000px;
`