import React, { useCallback } from "react"
import RichTextInput from "../../RichTextInput"
import { TextBlockProps } from "./types"

export const TextBlock = ({ onChange, defaultValue, id }: TextBlockProps) => {
  const handleOnChange = useCallback(
    (value: string) => {
      onChange?.(value)
    },
    [onChange]
  )

  return (
    <RichTextInput
      onChange={handleOnChange}
      defaultValue={defaultValue}
      id={id}
    />
  )
}

export default TextBlock
