import React, { useContext, useCallback, useMemo } from "react"
import { ListItemText, Typography } from "@mui/material"
import { NotesContext } from "../../contexts"
import { List, ListItem, Container, Footer } from "./NoteList.styled"
import { useParams } from "react-router-dom"
import { useHistory } from "react-router-dom"
import NewNoteInput from "../NewNoteInput"
import SettingsDialogue from "../SettingsDialogue"
import { orderBy } from "lodash"

export const NoteList = () => {
  const { notes } = useContext(NotesContext) ?? { notes: {} }
  const params = useParams<{ id: string }>()
  const history = useHistory()

  const notesSorted = useMemo(
    () =>
      orderBy(
        Object.keys(notes ?? {}).map((id) => ({ id, ...(notes ?? {})?.[id] })),
        `id`
      ),
    [notes]
  )

  const handleClick = useCallback(
    (id: string) => () => {
      history.push(`/${id}`)
    },
    [history]
  )

  return (
    <Container>
      <List disablePadding>
        {notesSorted &&
          notesSorted.map(({ id }) => (
            <ListItem
              onClick={handleClick(id)}
              $active={params?.id === id}
              key={id}
            >
              <ListItemText>
                <Typography variant="body1">
                  {notes?.[id]?.title ?? id}
                </Typography>
              </ListItemText>
            </ListItem>
          ))}
      </List>
      <Footer>
        <NewNoteInput /> <SettingsDialogue />
      </Footer>
    </Container>
  )
}

export default NoteList
