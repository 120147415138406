import React, { useState, useEffect, useContext, createContext } from "react";
import * as firebase from "firebase/app";
import "firebase/auth";
import config from "../config";

const firebaseApp = firebase.initializeApp(config.firebase);

firebase
  .firestore()
  .enablePersistence()
  .catch(function (err) {
    if (err.code === `failed-precondition`) {
      console.log(`Offline storage disabled with more than one tab open`);
    } else if (err.code === `unimplemented`) {
      console.log(
        `Browser doesn't support features needed for offline storage`
      );
    }
  });

export const authContext = createContext<any>(null);

export function ProvideAuth({ children }: { children: React.ReactChild }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuth = () => {
  return useContext(authContext);
};

function useProvideAuth() {
  const [user, setUser] = useState<firebase.User | null | undefined>(undefined);

  const signIn = () => {
    return firebase
      .auth()
      .signInWithPopup(new firebase.auth.GoogleAuthProvider())
      .then((result) => {
        return result;
      });
  };

  const signOut = () => {
    return firebase
      .auth()
      .signOut()
      .then(() => {
        setUser(null);
      });
  };

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  return {
    firebaseApp,
    user,
    signIn,
    signOut,
  };
}
